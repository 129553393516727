
























import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { roleService } from "@/app/services/role.service";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import {organisationService} from "@/app/services/organisation.service";

export type SelectOrgInRoleMetaData = {
  roleIds?: string[];
};

@Component({
  components: { AutocompleteWithErrors, AeAutocomplete },
})
export default class FormComponentSelectOrgInRole extends BaseDynamicComponent<SelectOrgInRoleMetaData> {
  orgs: string[] | string = [];
  eligibleOrganistions: { name: string; id: string }[] = [];
  loadingElegableOrganistions = false;

  postEntityChanged(entry: DynamicFormEntry) {
    if (
      entry &&
      Array.isArray(entry?.metadata?.roleIds) &&
      Array.from(entry?.metadata?.roleIds).length > 0
    ) {
      roleService
        .getEligableOrganisations(Array.from(entry?.metadata?.roleIds)[0])
        .then((eligibleOrganistions) => {
          this.eligibleOrganistions = eligibleOrganistions;
        })
        .catch((reason) => {
          console.warn(
            "Failed to fetch EligableOrganisations for " +
              Array.from(entry?.metadata?.roleIds)[0] +
              " " +
              reason
          );
          this.eligibleOrganistions = [];
        });
    } else {
      this.eligibleOrganistions = [];
    }
  }

  get displayOrganisations(): string {
    return this.eligibleOrganistions
      .filter((eligibleOrg) => this.orgs.includes(eligibleOrg.id))
      .map((org) => org.name)
      .join(", ");
  }

  @Watch("orgs")
  onOrgsChanged() {
    this.setValueView(this.orgs);
  }
  postSetValueView(value: any) {
    if (value !== this.orgs) {
      this.orgs = value;
    }
  }

  async afterSave(sumbitData: any): Promise<boolean> {
    if (!sumbitData?.type) return true;
    if (sumbitData.type === "dossier" && sumbitData.referenceId) {
      if (Array.isArray(this.entry.value)) {
        for (let org of Array.from(this.entry.value)) {
          await dossierServiceV2.addPartyToDossier(
            sumbitData.referenceId,
            "ORGANISATION",
            org,
            this.entry?.metadata?.roleIds[0]
          );
        }
      } else {
        await dossierServiceV2.addPartyToDossier(
          sumbitData.referenceId,
          "ORGANISATION",
          this.entry.value,
          this.entry?.metadata?.roleIds[0]
        );
      }
    }
    return true;
  }

  public async fillIndexerValue(indexerField: string, indexerSelector): Promise<any> {
    if('orgs' === indexerSelector){
      if(this.orgs && Array.isArray(this.orgs)){
        let filteredOrgs = this.eligibleOrganistions.filter((org) => this.orgs.includes(org.id));
        if(filteredOrgs.length < this.orgs.length){
          //this can happen with computed values, as elegable onlu checks first roleid
          const fetchedOrgs = await organisationService.getOrganisationsByIds(this.orgs);
          filteredOrgs = fetchedOrgs.map(value => {
            return {
              name: value.name,
              id: value.id
            }
          });
        }
        return filteredOrgs
            .map((org) => org.name)
            .join(", ") || "";
      }else if(typeof this.orgs === 'string'){
        let filteredOrgs = this.eligibleOrganistions.filter((org) => org.id === this.orgs);
        if(filteredOrgs.length < 1){
          //this can happen with computed values, as elegable onlu checks first roleid
          const fetchedOrgs = await organisationService.getOrganisationsByIds([this.orgs]);
          filteredOrgs = fetchedOrgs.map(value => {
            return {
              name: value.name,
              id: value.id
            }
          });
        }
        return filteredOrgs
            .map((org) => org.name)
            .join(", ") || "";
      }
    }

    return super.fillIndexerValue(indexerField, indexerSelector);
  }
}
