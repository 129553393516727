


























import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";
import TextArea from "@/components/common/Textarea.vue";
import { FormEvent } from "@/app/dynamic-components/forms/dynamic-form.model";

export type TextAreaMetaData = {
  label?: string;
  disabled?: boolean;
  value?: string;
};

@Component({
  components: { TextArea },
})
export default class TextAreaBase extends BaseDynamicComponent<TextAreaMetaData> {
  mounted(): void {
    return;
  }

  onTextFieldContentChanged(newValue: string) {
    this.setValueView(newValue);
  }
}
