


























import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { roleService } from "@/app/services/role.service";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { userServiceV2 } from "@/app/services/user.service";
import UserInfo from "@/contracts/generic/UserInfo";
import {scheduleService} from "@/app/services/schedule.service";
import {i18n} from "@/i18n";
import {organisationService} from "@/app/services/organisation.service";

export type SelectPersonInRoleMetaData = {
  roleIds?: string[];
  multiple: boolean;
  ownOrgOnly: boolean;

  checkSchedule: boolean
  scheduleBlockType: string
  scheduleBlockedMessage: string
};

@Component({
  components: { AutocompleteWithErrors },
})
export default class FormComponentSelectPersonInRole extends BaseDynamicComponent<SelectPersonInRoleMetaData> {
  persons: string[] | string = [];
  eligiblePersons: { name: string; id: string }[] = [];
  loadingElegablePersons = false;
  postEntityChanged(entry: DynamicFormEntry) {
    if (
      entry &&
      Array.isArray(entry?.metadata?.roleIds) &&
      Array.from(entry?.metadata?.roleIds).length > 0
    ) {
      roleService
        .getEligibleUsersForRole(Array.from(entry?.metadata?.roleIds)[0], entry?.metadata?.ownOrgOnly === false, this.$store.state.user.organizationId)
        .then((eligiblePersons) => {
          if(!this.entry?.metadata?.checkSchedule){
            this.eligiblePersons = eligiblePersons.map((value) => {
              return {
                name: value.fullName,
                id: value.id,
                available: true
              };
            });
          }else{
            scheduleService.filterAvailableContacts(this.entry?.metadata?.scheduleBlockType, eligiblePersons.map((value) => {
              return value.id;
            })).then(availableContacts => {
              const unavailableMessage = ' (' + i18n.tc(this.metadata?.scheduleBlockedMessage || 'general.blocked') + ')';
              const availableContactsLookup = {}
              availableContacts.forEach(value => availableContactsLookup[value.contactId] = true)

              this.eligiblePersons = eligiblePersons.map((value) => {
                const disabled = !(availableContactsLookup[value.id] || false);
                return {
                  name: disabled ? (value.fullName + unavailableMessage) : value.fullName,
                  id: value.id,
                  available: !disabled,
                  disabled: disabled
                };
              });//.filter(value => value.available);
            }).catch(reason => {
              console.error(reason);
              this.eligiblePersons = [];
            })

          }
        })
        .catch((reason) => {
          console.warn(
            "Failed to fetch eligiblePersons for " +
              Array.from(entry?.metadata?.roleIds)[0] +
              " " +
              reason
          );
          this.eligiblePersons = [];
        });
    } else {
      this.eligiblePersons = [];
    }
  }

  currentlySelectedUser: UserInfo | null = null;

  get displayPersons(): string {
    if (this.currentlySelectedUser)
      return (
        this.currentlySelectedUser.firstName +
        " " +
        this.currentlySelectedUser.lastName
      );
    return this.eligiblePersons
      .filter((person) => this.persons.includes(person.id))
      .map((person) => person.name)
      .join(", ");
  }

  @Watch("persons")
  onPersonsChanged() {
    this.setValueView(this.persons);
  }
  postSetValueView(value: any) {
    if (value !== this.persons) {
      this.persons = value;
      if (value) {
        userServiceV2.getUsersByIds([value]).then((value) => {
          this.currentlySelectedUser = value[0];
        });
      } else {
        this.currentlySelectedUser = null;
      }
    }
  }

  async afterSave(sumbitData: any): Promise<boolean> {
    if (!sumbitData?.type) return true;
    if (sumbitData.type === "dossier" && sumbitData.referenceId) {
      if (Array.isArray(this.entry.value)) {
        for (let user of Array.from(this.entry.value)) {
          await dossierServiceV2.addPartyToDossier(
            sumbitData.referenceId,
            "USER",
            user,
            this.entry.metadata?.roleIds[0]
          );
        }
      } else {
        await dossierServiceV2.addPartyToDossier(
          sumbitData.referenceId,
          "USER",
          this.entry.value,
          this.entry.metadata?.roleIds[0]
        );
      }
    }
    return true;
  }


  public async fillIndexerValue(indexerField: string, indexerSelector): Promise<any> {
    if('users' === indexerSelector){
      if(this.persons && Array.isArray(this.persons)){
        let filteredPersons = this.eligiblePersons.filter((person) => this.persons.includes(person.id));
        if(filteredPersons.length < this.persons.length){
          //this can happen with computed values, as elegable onlu checks first roleid
          const fetchedPersons = await  userServiceV2.getUsersByIds(this.persons);
          filteredPersons = fetchedPersons.map(person => {
            return {
              name: person.fullName,
              id: person.id
            }
          });
        }
        return filteredPersons
            .map((person) => person.name)
            .join(", ") || "";
      }else if(typeof this.persons === 'string'){
        let filteredPersons = this.eligiblePersons.filter((person) => person.id === this.persons);
        if(filteredPersons.length < 1){
          //this can happen with computed values, as elegable onlu checks first roleid
          const fetchedPersons = await userServiceV2.getUsersByIds([this.persons]);
          filteredPersons = fetchedPersons.map(person => {
            return {
              name: person.fullName,
              id: person.id
            }
          });
        }
        return filteredPersons
            .map((person) => person.name)
            .join(", ") || "";
      }
    }

    return super.fillIndexerValue(indexerField, indexerSelector);
  }


  onShow() {
    if(this.entry?.metadata?.checkSchedule){
      this.postEntityChanged(this.entry);
    }
  }
}
