import Vue from "vue";
import OverviewCellText from "@/app/dynamic-components/overviews/cells/components/overview-cell-text.vue";
import OverviewCellDate from "@/app/dynamic-components/overviews/cells/components/overview-cell-date.vue";
import OverviewCellState from "@/app/dynamic-components/overviews/cells/components/overview-cell-state.vue";
import OverviewCellAction from "@/app/dynamic-components/overviews/cells/components/overview-cell-action.vue";
import OverviewCellDownload from "@/app/dynamic-components/overviews/cells/components/overview-cell-download.vue";
import OverviewCellShare from "@/app/dynamic-components/overviews/cells/components/overview-cell-share.vue";
import OverviewCellStepcount from "@/app/dynamic-components/overviews/cells/components/exchange/overview-cell-stepcount.vue";
import OverviewCellExchangeAction from "@/app/dynamic-components/overviews/cells/components/exchange/overview-cell-exchange-action.vue";
import OverviewCellPerson from "@/app/dynamic-components/overviews/cells/components/overview-cell-person.vue";
import OverviewCellOrganisation from "@/app/dynamic-components/overviews/cells/components/overview-cell-organisation.vue";
import OverviewCellList from "@/app/dynamic-components/overviews/cells/components/overview-cell-list.vue";
import OverviewCellAddress from "@/app/dynamic-components/overviews/cells/components/overview-cell-address.vue";
import OverviewCellBreadcrumb from "@/app/dynamic-components/overviews/cells/components/overview-cell-breadcrumb.vue";
import OverviewCellLink from "@/app/dynamic-components/overviews/cells/components/overview-cell-link.vue";
import OverviewCellOverviewRef from "@/app/dynamic-components/overviews/cells/components/overview-cell-overview-ref.vue";
import OverviewCellPlanning from "@/app/dynamic-components/overviews/cells/components/overview-cell-planning.vue";
import OverviewCellOrganisationAndPersons
  from "@/app/dynamic-components/overviews/cells/components/overview-cell-org-and-persons.vue";
import OverviewCellContactdetails from "@/app/dynamic-components/overviews/cells/components/overview-cell-contactdetails.vue";
import OverviewCellCategory from '@/app/dynamic-components/overviews/cells/components/overview-cell-category.vue';
import FormComponentTreeCheckboxEntry from "@/app/dynamic-components/forms/form-components/components/form-component-tree-checkbox-entry.vue";

export default class OverviewCellDependencies {
  public registerDependencies() {
    Vue.component("OverviewCellText", OverviewCellText);
    Vue.component("OverviewCellDate", OverviewCellDate);
    Vue.component("OverviewCellCategory", OverviewCellCategory);
    Vue.component("OverviewCellState", OverviewCellState);
    Vue.component("OverviewCellAction", OverviewCellAction);
    Vue.component("OverviewCellDownload", OverviewCellDownload);
    Vue.component("OverviewCellShare", OverviewCellShare);
    Vue.component("OverviewCellStepcount", OverviewCellStepcount);
    Vue.component("OverviewCellExchangeAction", OverviewCellExchangeAction);
    Vue.component("OverviewCellPerson", OverviewCellPerson);
    Vue.component("OverviewCellOrganisation", OverviewCellOrganisation);
    Vue.component("OverviewCellList", OverviewCellList);
    Vue.component("OverviewCellAddress", OverviewCellAddress);
    Vue.component("OverviewCellBreadcrumb", OverviewCellBreadcrumb);
    Vue.component("OverviewCellLink", OverviewCellLink);
    Vue.component("OverviewCellOverviewRef", OverviewCellOverviewRef);
    Vue.component("OverviewCellPlanning", OverviewCellPlanning);
    Vue.component("OverviewCellOrganisationAndPersons", OverviewCellOrganisationAndPersons);
    Vue.component("OverviewCellContactdetails", OverviewCellContactdetails);
    Vue.component("FormComponentTreeCheckboxEntry", FormComponentTreeCheckboxEntry);
  }
}

export const overviewCellDependencies = new OverviewCellDependencies();
