
















import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import LottieImage from "@/app/components/lottie-image.vue";
import Loader from "@/components/common/Loader.vue";
import {ActivityMaintenanceDefinitionModel} from "@/app/models/activity/activity-definition.model";
import PageHeader from "@/components/layout/PageHeader.vue";
import {configService} from "@/services/config.service";
import router from "@/router";
import {iamService} from "@/services/iam.service";

@Component({
  components: {
    PageHeader,
    Loader,
    LottieImage,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor

  },
})
export default class Maintenance extends ActivityDependendViewBase {

  get attrs() {
    return {
      class: "mb-6",
      boilerplate: false,
      elevation: 0,
    };
  }

  private maintenance: ActivityMaintenanceDefinitionModel | null = null;
  private isMaintenanceActive=  false;
  private hasApiFailed = false;
  private hasApiFailedRetryTimer: any;
  private activityMaintenceWindowTimer: any;
  private retryCount = 0;
  private retryInterval = 0;

  private headerTitle = '';
  private messageTitle = '';
  private message = '';
  private type: ('construction' | 'temporary_unavailable' | 'api_down' | undefined) = undefined
  private imageMap = {
    'construction': 'lottie_maintenance.json',
    'temporary_unavailable': 'lottie_under_construction.json',
    'api_down': 'lottie_api_error.json'
}
  private image: string | undefined = undefined;



  async mounted(): Promise<void> {
    const apiFailedOnLoad = this.$route.query['api-failed'] === 'true';
    if(apiFailedOnLoad){
      await this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, 'api-failed': undefined }
      });
    }
    this.testApiAvailable(apiFailedOnLoad);
  }

  beforeDestroy(): void {
    if(this.hasApiFailedRetryTimer){
      clearInterval(this.hasApiFailedRetryTimer);
      this.hasApiFailedRetryTimer = undefined;
    }
    if(this.activityMaintenceWindowTimer){
      clearInterval(this.activityMaintenceWindowTimer)
      this.activityMaintenceWindowTimer = undefined;
    }
  }

  async onActivityUpdated(): Promise<any> {
    if(!this.activityMaintenceWindowTimer){
      this.activityMaintenceWindowTimer = setInterval(this.onActivityUpdated, 60000);
    }


    if(this.activityDefinition?.maintenance){
      this.maintenance = this.activityDefinition.maintenance
      const now = new Date().getTime();
      this.isMaintenanceActive = this.activityDefinition.maintenance.startTime && this.activityDefinition.maintenance.startTime.getTime() < now
      if(this.activityDefinition.maintenance.endTime && this.activityDefinition.maintenance.endTime.getTime() < now ) this.isMaintenanceActive = false;

      if(!this.isMaintenanceActive){
        this.$toast.success(this.$t("maintenance.general.resolved"));
        await this.$router.push({path: '/', replace: true});
      }

      this.headerTitle = this.maintenance.title || 'maintenance.general.title';
      this.messageTitle = this.maintenance.messageTitle || 'maintenance.general.messageTitle';
      this.message = this.maintenance.message || 'maintenance.general.message';
      this.type = this.maintenance.type || "temporary_unavailable";
      this.image = this.imageMap[this.type];
    }else{
      this.maintenance = null;
      this.isMaintenanceActive = false;
    }
  }


  private async testApiAvailable(alreadyTested: boolean) {
    if(!alreadyTested){
      try{
        const apiResponse = await iamService.getConfig();
        this.hasApiFailed = apiResponse.status !== 200;
      }catch (e) {
        this.hasApiFailed = true;
      }
    }else{
      this.hasApiFailed = true;
    }

    if(this.hasApiFailed){
      this.headerTitle = 'Maintenance';
      this.messageTitle = 'We are unable to connect to the servers at this time';
      this.message = 'This can be due to maintenance on our side, or bad internet reception on your device. Please wait if the issue resolves itself, or try again at a later time.';
      this.type = "api_down";
      this.image = this.imageMap[this.type];

      this.retryCount = 1;
      this.retryInterval = 10;
      this.hasApiFailedRetryTimer = setInterval(this.retryFunction, 1000);
    }else{
      //this.resolveApiFailure();
      return;
    }
  }

  private async retryFunction(){
    this.retryInterval -= 1;
    if(this.retryInterval <= 0){
      clearInterval(this.hasApiFailedRetryTimer);
      this.hasApiFailedRetryTimer = undefined;
      try{
        const apiResponse = await iamService.getConfig();
        this.hasApiFailed = apiResponse.status !== 200;
      }catch (e) {
        this.hasApiFailed = true;
      }
      this.retryCount += 1;
      this.retryInterval = (10 * this.retryCount * this.retryCount)
      if(!this.hasApiFailed){
        this.resolveApiFailure();
      }else{
        this.hasApiFailedRetryTimer = setInterval(this.retryFunction, 1000);
      }
    }
  }

  private resolveApiFailure() {
    if(this.hasApiFailedRetryTimer){
      clearInterval(this.hasApiFailedRetryTimer);
      this.hasApiFailedRetryTimer = undefined;
    }
    this.$toast.success(this.$t("maintenance.apifailed.resolved"));
    setTimeout(async () => {
      window.location.reload();
    },100);
  }
}
