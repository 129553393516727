
































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import { exchangeService } from "@/app/services/exchange.service";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { ExchangeEvent } from "@/app/components/exchange/exchange.model";
import { Subject, Subscription } from "rxjs";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import TextField from "@/components/common/TextField.vue";
import FileInput from "@/components/common/FileInput.vue";
import DossierDetailDocumentExchanges from "@/app/components/dossier-detail-document-exchanges.vue";
import DetailComponentExchangeSteps from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchange-steps.vue";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { productService } from "@/app/services/product.service";
import { ruleEngine } from "@/app/services/rule.engine";
import store from "@/store";

export type DetailProductsMetaData = {
  overviewDefinitionId: string;
  path: string;
  referenceType: string;
  referenceId: string;
};

@Component({
  components: { FormDialogComponent, OverviewComponent, FileInput, TextField },
  computed: {
    RenderType() {
      return RenderType;
    },
  },
})
export default class DetailComponentProducts extends DetailComponentBase<DetailProductsMetaData> {
  linkProductControl: FormDialogControl | null = null;
  overviewControl: OverviewControl | null = null;

  postEntityChanged(entry: DynamicDetailEntry) {
    if (entry) {
      this.linkProductModal.referenceId = this.metadata.referenceId;
      this.linkProductModal.referenceType = this.metadata.referenceType;
      const activityDefinition = this.$store.state.activity
        .currentActivityDefinition as ActivityDefinitionModel;
      this.linkProductModal.externalContext.setData(
        "activitydefinition",
        activityDefinition
      );
    }
  }

  openProductDialog() {
    this.linkProductControl?.show();
  }

  recieveLinkProductModal(control: FormDialogControl) {
    this.linkProductControl = control;
    control.submitChanges = this.linkProducts;
    control.afterSubmitChanges = this.refreshOverview;
  }

  recieveOverviewControl(control: OverviewControl) {
    this.overviewControl = control;
  }

  async linkProducts(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (Array.isArray(formData)) {
      const productIds = Array.from(formData);
      for (let i = 0; i < productIds.length; i++) {
        const success = await productService.linkProduct(
          productIds[i],
          this.metadata.referenceId,
          this.metadata.referenceType
        );
        if (!success) {
          return false;
        }
      }
    }
    return true;
  }

  async deleteProductLink(event: OverviewEvent) {
    if (event?.item?.product?.id) {
      await productService.unlinkProduct(event.item.product.id, event.item.id);
      await this.refreshOverview();
    }
  }

  async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    return true;
  }

  private linkProductModal: FormDialogModel = {
    submitLabel: "linkproduct.submit",
    referenceId: "",
    referenceType: "",
    destroyLabel: null,
    title: "linkproduct.title",
    externalContext: new ExternalContext(),
    form: {
      id: "root",
      name: "",
      type: RenderType.SelectFromOverview,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {
        path: `api/v2/products`,
        overviewDefinitionId: "432819ae-6d61-ee11-8df0-6045bd895bfb",
        selectableProperty: "id",
      },
      children: [],
    },
  };
}
