














































































import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/app/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import { FormDialogControl } from "@/app/components/dialog/form-dialog.model";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import { ExternalContext } from "@/app/contexts/externalContext";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { ruleEngine } from "@/app/services/rule.engine";
import { documentServiceV2 } from "@/app/services/document.service";
import ShareDocumentDialogComponent from "@/app/components/dialog/share/share-document-dialog-component.vue";

@Component({
  components: {
    ShareDocumentDialogComponent,
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class DocumentsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};
  externalContext: ExternalContext = new ExternalContext();
  rules: { [key: string]: boolean } = {};

  async onActivityUpdated(): Promise<any> {
    if (this.activityDefinition.version === "v1") return;

    const categories = this.activityDefinition.generalDocuments.entries
      .flatMap((value) => Object.entries(value.categories))
      .map((entry) => {
        return { id: entry[0], label: entry[1].label, types: entry[1].types };
      });

    this.additionalFilters.ae_documentcategory = categories.map(value => value.id).join(';')

    const entries = this.activityDefinition.generalDocuments.entries[0];

    const types = this.activityDefinition.generalDocuments.entries
      .flatMap((value) => Object.entries(value.types))
      .map((entry) => {
        return { id: entry[0], label: entry[1] };
      });

    this.externalContext.setData("types", types);
    this.externalContext.setData("categories", categories);
    this.externalContext.setData("entries", entries);
    this.additionalMetadata.types = types;
    this.additionalMetadata.categories = categories;

    const resolvedRules: { [key: string]: string } = this.externalContext.resolvePlaceholders(this.activityDefinition.generalDocuments.rules || {});
    this.rules = {};
    Object.entries(resolvedRules).map((e) => {
      this.rules[e[0]] = ruleEngine.resolveRule(
        this.externalContext,
        (e[1] as string) || ""
      );
    });
    return;
  }

  private documentItem: any = null;
  private overviewControl: OverviewControl | null = null;
  protected documentDetailControl: FormDialogControl | null = null;
  protected documentUploadControl: FormDialogControl | null = null;
  protected documentModifyControl: FormDialogControl | null = null;

  public recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  protected recieveDocumentModifyControl(control: FormDialogControl) {
    this.documentModifyControl = control;
    control.submitChanges = this.modifyDocument;
    control.destroyItem =this.deleteDocument;
    control.afterSubmitChanges = this.setExternalEntity;
  }

  protected recieveDocumentDetailsControl(control: FormDialogControl) {
    this.documentDetailControl = control;
    control.submitChanges = this.modifyDocument;
    control.destroyItem =this.deleteDocument;
    control.afterSubmitChanges = this.setExternalEntity;
  }

  protected recieveUploadDocumentControl(control: FormDialogControl) {
   this.documentUploadControl = control;
    control.submitChanges = this.uploadDocument;
    control.afterSubmitChanges = this.setExternalEntity;
  }

  showUploadDialog() {
    this.documentUploadControl?.show();
  }

  public async setExternalEntity(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    return true;
  }

  showShareDialog = false;

  public rowClicked(event:{item: any, index:number}){
    if(!event.item['documentId'] && event.item['id'])
      event.item['documentId'] = event.item['id'];
    documentServiceV2.downloadDocument(event.item);
  }
  public actionPressed(overviewEvent: OverviewEvent) {
    if (overviewEvent.metadata.eventId === "show") {
      this.documentItem = overviewEvent.item;

      if(overviewEvent.metadata.action && overviewEvent.metadata.action === 'edit')
        this.documentModifyControl?.show(overviewEvent.item.id,overviewEvent.item);
      else
        this.documentDetailControl?.show(overviewEvent.item.id,overviewEvent.item);

      setTimeout(() => {
        this.overviewControl?.sendEvent({
          type: "CLEAR_ACTION",
          item: null,
          metadata: {},
        });
      }, 2000);
      return;
    }
    if (overviewEvent.metadata.eventId == "share") {
      this.documentItem = overviewEvent.item;
      this.showShareDialog = true;
    }
  }

  savingDocument = false;

  public async uploadDocument(): Promise<boolean> {
    this.documentItem = null;
    this.overviewControl?.refresh();
    return Promise.resolve(true);
  }

  public async modifyDocument(form: DynamicForm): Promise<boolean> {
    this.savingDocument = true;
    const metadata = await documentServiceV2.getDocumentMetadata(
      this.documentItem.id
    );
    metadata.description = form.formData.description;
    metadata.type = form.formData.type;

    return documentServiceV2
      .updateDocumentMetadata(this.documentItem.id, metadata)
      .then(() => {
        this.savingDocument = false;
        this.documentItem = null;
        this.overviewControl?.refresh();
        return true;
      });
  }

  public async deleteDocument(): Promise<boolean> {
    this.savingDocument = true;
    return documentServiceV2
      .deleteDocumentById(this.documentItem.id)
      .then(() => {
        this.savingDocument = false;
        this.documentItem = null;
        this.overviewControl?.refresh();
        (this.$refs.detailDialog as any)?.closeDialog();
        return true;
      });
  }
}
