import axios from "@/plugins/axios";
import IamUserInfo from "@/contracts/iam/IamUserInfo";
import IamConfig from "@/contracts/iam/IamConfig";
import store from "@/store";
import {AxiosResponse} from "axios";

class IamService {
  public getConfig(): Promise<AxiosResponse<IamConfig>> {
    return axios.get(`${store.state.appConfig.apiBaseUrl}api/iam/config`);
  }

  public getUserInfo(
    organizationId: string,
    userId: string
  ): Promise<IamUserInfo> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/iam/organizations/${organizationId}/users/${userId}`)
      .then((response) => {
        return response?.data?.user;
      });
  }
}

export const iamService = new IamService();
