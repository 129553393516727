import axios from "@/plugins/axios";
import { LocaleMessages } from "vue-i18n";
import store from "@/store";
import { Language } from "@/contracts/common/Language";
import { BehaviorSubject, Subscription } from "rxjs";
import {configService} from "@/services/config.service";

class TranslationService {

  private _supportedLanguages = new BehaviorSubject<string[]>(["NL", "FR", "EN"]);
  public supportedLanguages() {
    return this._supportedLanguages.asObservable();
  }

  getUserLanguage(): Language {
    let userLanguage =
      store.state.user.language ||
      navigator.language.split("-")[0].toUpperCase();

    if (!this._supportedLanguages.value.includes(userLanguage)) {
      userLanguage = "EN";
    }

    return userLanguage;
  }

  public async getTranslations(language: Language): Promise<LocaleMessages> {
    if (language === "NAN") return {};
    try {
      const response = await axios.get(
        `${store.state.appConfig.apiBaseUrl}api/translations/${language}`
      );
      if (!response?.data) return {};
      return response.data;
    } catch (reason) {
      return {};
    }
  }

  setDebugTranslations(debugTranslationsEnabled: boolean) {
    this._supportedLanguages.next(debugTranslationsEnabled ? ["NL", "FR", "EN", "NAN"] : ["NL", "FR", "EN"]);
  }
}

export const translationService = new TranslationService();
