import EventBase from "@/app/events/event-base";
import router, { Routes } from "@/router";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import { Vue } from "vue-property-decorator";

export default class EditDossierEvent extends EventBase {
  async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
    router.push({ name: Routes.EditDossierV2 });
  }
}
