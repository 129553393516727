import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import {checkinService} from "@/app/services/checkin.service";
import {DynamicForm} from "@/app/dynamic-components/forms/dynamic-form.model";
import {dossierServiceV2} from "@/app/services/dossier.service";

export default class ShowCheckinEvent extends EventBase {
    async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
        if(!(context instanceof DetailContext)) return Promise.reject('Context is not a detailcontext');
        const detailsContext: DetailContext = context;

        if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent) {
            detailsContext.root.setModal(payload.dialogDefinitionId, payload.referenceId, detailsContext, payload.data, (form, formData, referenceId) => {
                return this.createCheckin(form, formData, referenceId || '', detailsContext, payload, rawPayload);
            }, (form, formData, referenceId) => Promise.resolve(true),
            async (form, formData, referenceId, multiplicityReferenceId) => {
                if(multiplicityReferenceId){
                    const newDetailContext = detailsContext?.Clone();
                    if (payload['regardingType'] === 'lot') {
                        const lot = await dossierServiceV2.getLot(payload.referenceId, multiplicityReferenceId);
                        newDetailContext?.setData("lot", lot);
                    }
                    form.patchForm({
                        regardingId: multiplicityReferenceId,
                        referenceId: multiplicityReferenceId,
                        regardingType: payload['regardingType'],
                        markExecutorsAsBusy: false,
                        linkName: newDetailContext?.resolvePlaceholders(rawPayload['linkName']),
                        linkPath: newDetailContext?.resolvePlaceholders(rawPayload['linkPath'])
                    });
                }
                return true;
            });
        }
    }

    async createCheckin(
        form: DynamicForm,
        formData: any,
        referenceId: string,
        detailsContext: DetailContext,
        payload: any,
        rawPayload: any
    ): Promise<boolean> {
        try{
            const referenceId = await checkinService.createCheckin(form, formData);
            if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent){
                const modal = detailsContext.root.getModal();
                if(modal){
                    modal.setReferenceId(referenceId);
                    modal.setReferenceType("checkin");
                }
            }
            return true;
        }catch (e) {
            console.error(e);
            return false;
        }
    }
}
