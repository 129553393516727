import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import axios from "@/plugins/axios";
import Vue from "vue";
import store from "@/store";
import {i18n} from "@/i18n";
import router from "@/router";
export default class UpdateStatusEvent extends EventBase {
    async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
        if (!payload.fetchUrl || !payload.patchUrl) {
            Vue.$toast.error(i18n.tc("general.update_status.error.missing_urls"));
            return;
        }

        if(payload.fetchUrl && payload.fetchUrl.startsWith('api/')){
            payload.fetchUrl = store.state.appConfig.apiBaseUrl + payload.fetchUrl
        }
        if(payload.patchUrl && payload.patchUrl.startsWith('api/')){
            payload.patchUrl = store.state.appConfig.apiBaseUrl + payload.patchUrl
        }

        let object = {};
        try {
            object = (await axios.get(`${payload.fetchUrl}`)).data;
            object["status"] = payload.updateStatus;
        }catch (ex){
            return;
        }

        axios
            .put(`${payload.patchUrl}`, object)
            .then(async () => {
                const p = router.currentRoute.path;
                const q = router.currentRoute.query;
                Vue.$toast.success(i18n.tc("general.success"));

                await router.push({path: '/placeholder', replace: true});
                setTimeout(async () => {
                    await router.push({path: p, query: q, replace: true});
                }, 100);
            })
            .catch(() => {
                Vue.$toast.error(i18n.tc("general.error_occurred"));
            });
    }
}
