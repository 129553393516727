
















import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";
import {DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";
import FormComponentTreeCheckboxEntry from "@/app/dynamic-components/forms/form-components/components/form-component-tree-checkbox-entry.vue";
import FormComponentErrorMessage from "@/app/dynamic-components/forms/form-validations/form-component-error-message.vue";
import {translationService} from "@/services/translation.service";

export type TreeCheckBoxMetaData = {
  color?: string;
  dataSelector: string //in case of data in structure
  items: Array<{[key: string]: any}> //in case of data in definition

  itemText: string;
  translateText : boolean;
  itemId: string;
  itemValue: string
  itemChild: string;

  flatmap: boolean //flatmap the outcome to an array
  output: string //output value as object or list(default)
  autoSelectChildren: boolean //enable to select children with parent click
  autoSelectParent: boolean //enable to select children with parent click

  minimalSelectableDepth: number //prevent checkboxes on depths sooner than this
  maximalSelectableDepth: number //prevent checkboxes on depths deeper than this



  filterElements: string //dataref to filter the tree with not needed items
  filterElementsItemId: string //string key to filter the elements on when objects
  filterElementsCompareId: string //string key to filter the elements on when objects
};


export interface TreeEntry {
    parent: TreeEntry | null
    id: string;
    text: string;
    templateValue: any
    children: Array<TreeEntry>;
    state: any;
}

@Component({
  components: {FormComponentErrorMessage, FormComponentTreeCheckboxEntry}
})
export default class FormComponentTreeCheckbox extends BaseDynamicComponent<TreeCheckBoxMetaData> {

  public myself: FormComponentTreeCheckbox | null = null;
  public tree : Array<TreeEntry> = []
  onCheckBoxValueChanged(): void {
    if(this.entry?.metadata?.output === 'object'){
      const collector: {[key: string]: any} = {}
      this.fillValueObject(collector, this.tree, this.entry.metadata.flatmap);
      this.setValueView(collector);
    }else{
      const collector: Array<any> = []
      this.fillValueList(collector, this.tree, this.entry.metadata.flatmap);
      this.setValueView(collector);
    }
    return;
  }

  onFormChanged() {
    const previoursValue = this.value;
    this.resolveTree();
    this.setTreeByValue(this.tree, previoursValue);
    const collector: Array<any> = []
    this.fillValueList(collector, this.tree, this.entry.metadata.flatmap);
    if(JSON.stringify(previoursValue) != JSON.stringify(collector)){
      this.setValueView(collector);
    }
  }

  hasSizeValidator(): boolean{
    if(!this.entry?.validators) return false;
    return this.entry.validators.filter(value => value.getKey().includes('minSize')).length > 0;
  }

  postSetValueView(value: any) {
    this.setTreeByValue(this.tree, value);
    //set values in tree
  }

  postEntityChanged(newEntry: DynamicFormEntry) {
    this.myself = this;
    if(!newEntry){
      this.tree = [];
      return;
    }
    this.resolveTree();
  }

  protected getDefaultValue(): any {
    return {};
  }

  private fillTree(tree: TreeEntry, items: Array<{ [p: string]: any }>, metadata: any) {
    items.forEach(e => {
      if(e[metadata.itemId] !== undefined){
        const te = {
          parent: tree,
          id: e[metadata.itemId],
          text: e[metadata.itemText],
          templateValue: e[metadata.itemValue] || true,
          children: [],
          state: this.entry?.metadata?.itemValue ? null : false
        } as TreeEntry
        if(e[metadata.itemChild] && Array.isArray(e[metadata.itemChild])){
          this.fillTree(te, e[metadata.itemChild], metadata);
        }
        if(e[metadata.itemChild] && typeof e[metadata.itemChild] === 'object'){
          let arr: Array<any> = []
          Object.entries(e[metadata.itemChild]).forEach(value => {
            if(value[1] && typeof value[1] === 'object'){
              value[1]['key'] = value[0];
              arr.push(value[1]);
            }
          })
          this.fillTree(te, arr, metadata);
        }
        tree.children.push(te);
      }
    })
  }

  private fillValueObject(collector: { [p: string]: any}, tr: Array<TreeEntry>, flatmap: boolean) {
    tr.forEach(value => {
      if(value.children.length >0){
        if(flatmap){
          collector[value.id] = value.state;
          this.fillValueObject(collector, value.children, flatmap);
        }else{
          collector[value.id] = {}
          this.fillValueObject(collector[value.id], value.children, false);
        }
      }else{
        collector[value.id] = value.state;
      }
    })
  }

  private fillValueList(collector: Array<any>, tr: Array<TreeEntry>, flatmap: boolean) {
    tr.forEach(value => {
      if(value.children.length >0){
        if(flatmap){
          if(value.state !== null){
            collector.push(value.state);
          }
          this.fillValueList(collector, value.children, flatmap);
        }else{
          const childArray: Array<any> = [];
          this.fillValueList(childArray, value.children, false);
          collector.push(childArray);
        }
      }else{
        if(value.state !== null){
          collector.push(value.state);
        }
      }
    })
  }

  private setTreeByValue(tree: Array<TreeEntry>, value: any) {
    tree.forEach(e => {
      if(this.entry.metadata.flatmap){
        if(this.entry.metadata.output === 'object'){
          e.state = value[e.id];
        }else{
          if(value && Array.from(value).includes(e.id)){
            if(this.entry.metadata.itemValue){
              e.state = e.id
            }else{
              e.state = true;
            }
          }
        }
        this.setTreeByValue(e.children, value);
      }else{
        this.setTreeByValue(e.children, value[e.id]);
        this.iterateChildrenUp(e);
        this.iterateChildrenDown(e.state, e);
      }
    })
  }

  public iterateChildrenDown(filled: boolean, entry: TreeEntry) {
    if(entry.children.length > 0){
      entry.children.forEach(value => {

        let newValue: any;
        if (!filled) {
          newValue = this.entry?.metadata?.itemValue ? null : false;
        }else{
          newValue = this.entry?.metadata?.itemValue ? value.templateValue : true;
        }
        value.state = newValue;
        this.iterateChildrenDown(filled, value);
      });
    }
  }
  public iterateChildrenUp(entry: TreeEntry) {
    if(entry.parent && entry.parent.id != ''){
      const allSet = entry.parent.children.filter(value => value.state).length === entry.parent.children.length;
      console.warn('AllSet', allSet, entry.parent.id, this.entry?.metadata?.itemValue, allSet ? entry.parent.templateValue : null)
      if(this.entry?.metadata?.itemValue){
        entry.parent.state = allSet ? entry.parent.templateValue : null;
      }else{
        entry.parent.state = allSet;
      }
      this.iterateChildrenUp(entry.parent);
    }
  }

  private filterTree(tree: Array<TreeEntry>, filterElements: Array<any>, itemId: string) {
    if(!tree) return tree;
    const foundElements: Array<TreeEntry> = [];
    for (let treeEntry of tree) {
      //if found add
      if(itemId && filterElements.includes(treeEntry[itemId])){
        foundElements.push(treeEntry);
      }
      if(!itemId && filterElements.includes(treeEntry)){
        foundElements.push(treeEntry);
      }

      //if no childeren, can never be added
      if(!treeEntry.children || treeEntry.children.length <= 0)
        continue;

      const foundChilderenElements = this.filterTree(treeEntry.children, filterElements, itemId);
      if(foundChilderenElements && foundChilderenElements.length > 0){
        treeEntry.children = foundChilderenElements;
        foundElements.push(treeEntry);
      }
    }
    return foundElements;
  }


  async afterSave(sumbitData: any): Promise<boolean> {
    this.postEntityChanged(this.entry);
    return true;
  }

  public async fillIndexerValue(indexerField: string, indexerSelector): Promise<any> {
    let collector: Array<any> = [];
    this.fillTextList(collector, this.tree);
    if(this.entry.metadata.translateText){
      collector = collector.map(value => this.$t(value));
    }
    return collector.join(', ');
  }

  private fillTextList(collector: any[], tree: Array<TreeEntry>) {
    tree.forEach(value => {
      if(value.state){
        collector.push(value.text);
      }
      if(value.children && value.children.length > 0){
        this.fillTextList(collector, value.children);
      }
    })
  }

  private resolveTree() {
    if(!this.entry){
      this.tree = [];
      return;
    }

    const metadata = this.entry.root?.resolvePlaceholders(this.entry.metadata);
    if(!metadata){
      this.tree = [];
      return;
    }
    if(!metadata.itemId){
      this.tree = [];
      return;
    }
    if(!metadata.itemText){
      metadata.itemText = metadata.itemId
    }


    let items : Array<{[key: string]: any}> = [];
    let it : any;
    if(metadata.dataSelector){
      it = this.entry.root?.resolveDataPath(metadata.dataSelector);
    }else if(this.entry.metadata.items){
      it = metadata.items
    }
    if(Array.isArray(it)){
      items = it;
    }else if(it && typeof it === 'object'){
      Object.entries(it).forEach(value => {
        if(value[1] && typeof value[1] === 'object'){
          value[1]['key'] = value[0];
          items.push(value[1]);
        }
      })
    }
    if(!items || !Array.isArray(items)){
      this.tree = [];
      return;
    }

    const te = {
      parent: null,
      id: '',
      text: '',
      templateValue: '',
      children: [],
      state: false
    } as TreeEntry
    this.fillTree(te, items, metadata);
    this.tree = te.children;

    if(metadata.filterElements){
      let filterElements = this.entry.root?.resolveDataPath(metadata.filterElements);
      if(filterElements){
        if(!Array.isArray(filterElements) && typeof filterElements === 'object'){
          const filterElementsArray: Array<any> = [];
          Object.entries(filterElements).map(value => {
            if(value[1] && typeof value[1] === "object"){
              value[1]['key'] = value[0]
              filterElementsArray.push(value[1]);
            }

          })
          filterElements = filterElementsArray;
        }
        if(metadata.filterElementsItemId){
          filterElements = filterElements.map(a => a[metadata.filterElementsItemId]);
        }
        this.tree = this.filterTree(this.tree, filterElements,  metadata.filterElementsCompareId || 'id')
      }
    }
  }
}
