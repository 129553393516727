import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import { Vue } from "vue-property-decorator";
import { documentServiceV2 } from "@/app/services/document.service";

export default class UpdateDocumentEvent extends EventBase {
  async getCallBack(context: ContextAware, payload?: any): Promise<any> {
    if (!payload.formView) return;
    const item = payload.formView.formData;
    if (!item?.id) return;

    const errors = await payload.formView.getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      Vue.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      Vue.$toast.error("Multiple validations failed.");
      return;
    }

    const metadata = await documentServiceV2.getDocumentMetadata(item.id[0]);
    metadata.description = item.description;
    metadata.type = item.type;
    return await documentServiceV2.updateDocumentMetadata(item.id, metadata);
  }
}
