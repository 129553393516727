import { RenderType } from "@/app/Types";

export interface DynamicFormEntryModel {
  id: string;
  name: string;
  type: RenderType;
  displayMode: "editable" | "inherit" | "rule" | "readonly" | "hidden";
  displayModeRule: string;
  computedValue: string;
  metadata: { [key: string]: any };
  children?: DynamicFormEntryModel[];
  watchFormChanges?: boolean
}

export const defaultDynamicFormEntry: DynamicFormEntryModel = {
  id: "default",
  children: [],
  name: "",
  type: RenderType.LinearSection,
  computedValue: "",
  displayMode: "editable",
  displayModeRule: "",
  metadata: {}
};
