export type RootRenderItem = {
  form: RenderItem[];
  watchFormChanges?: boolean
};

export type RenderItem = {
  id: string;
  name: string;
  type: RenderType;
  displayMode: "" | "inherit" | "rule" | "readonly" | "editable" | "hidden";
  displayModeRule: string;
  computedValue: string;
  validators?: string[];
  metadata?: any;
  children?: RenderItem[];
  watchFormChanges?: boolean;
};


export enum RenderType {
  TestSection = "TestSection",
  ACORDEON = "Acordeon",
  SECTION = "Section",
  TEXTFIELD = "TextField",
  ADDRESSFORM = "AddressForm",
  CONTACTDETAILSFORM = "ContactDetailsForm",
  PLANNINGFORM = "PlanningForm",
  DETAILTEXT = "DossierDetailComponentText",
  LinearSection = "LinearSection",
  SELECT = "Select",
  Switch = "Switch",
  FileInput = "FileInput",
  TextArea = "TextArea",
  Header = "Header",
  Planner = "Planner",
  DateTimePicker = "DateTimePicker",
  DatePicker = "DatePicker",
  TimePicker = "TimePicker",
  SelectOrg = "SelectOrg",
  Lots = "Lots",
  SelectPerson = "SelectPerson",
  SELECTV2 = "Selectv2",
  SelectOverviewItem = "SelectOverviewItem",
  FormComponentMulticheckinSelector = "FormComponentMulticheckinSelector",
  SelectFromOverview = "SelectFromOverview",
  CheckBox = "CheckBox",
  SelectOrgPerson = "SelectOrgPerson",
  FormSectionLinearMultiplicity = "FormSectionLinearMultiplicity",
  LinearSectionMultiplicity = "LinearSectionMultiplicity"
}

export function getEntryTypeFromRenderType(
  renderType: RenderType
): "item" | "group" | "array" {
  switch (renderType) {
    case RenderType.ACORDEON:
    case RenderType.SECTION:
    case RenderType.LinearSection:
    case RenderType.FormSectionLinearMultiplicity:
    case RenderType.LinearSectionMultiplicity:
      return "group";
    default:
      return "item";
  }
}

export type isValidObject = {
  valid: boolean;
  id: string;
};
