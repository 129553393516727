



import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Component } from "vue-property-decorator";
import { documentService } from "@/services/document.service";
import {documentServiceV2} from "@/app/services/document.service";

@Component({
  components: {},
})
export default class OverviewCellDownload extends OverviewCellBase {
  mounted(): void {
    const i = 0;
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "grey-darken-2";
  }

  get showIcon(): boolean {
    if (!this.metadata) return true;
    if (this.metadata["alwayShow"]) return true;

    if(!this.value) return false;
    if(Array.isArray(this.value) && this.value.length <= 0) return false;

    return true;
  }

  async downloadDocument(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if(!this.value) return;
    if(Array.isArray(this.value)){
      for (const v of this.value) {
          //const fileMetadata = await documentServiceV2.getDocumentMetadata(v);
          await documentServiceV2.downloadDocumentById(v);
      }
    }else if(typeof this.value === 'string'){
      //const fileMetadata = await documentServiceV2.getDocumentMetadata(this.value);
      await documentServiceV2.downloadDocumentById(this.value);
    }
    //documentService.getDocumentById(this.item["id"], this.item["name"]);
  }
}
