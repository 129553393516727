
































































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/app/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { RenderType } from "@/app/Types";
import { ExternalContext } from "@/app/contexts/externalContext";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { OverviewControl, OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import { checkinService } from "@/app/services/checkin.service";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import {ruleEngine} from "@/app/services/rule.engine";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class CheckinsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};
  externalContext: ExternalContext = new ExternalContext();
  public canCreateNew = false;

  async onActivityUpdated(): Promise<any> {
    const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
    const detailDefinitionId = activityDefinition.dossiers.entries[0].dossierDetailDefinitionId;

    if(activityDefinition.checkins.canCreateNew){
      this.canCreateNew = ruleEngine.resolveRule(this.externalContext, activityDefinition.checkins.canCreateNew);
    }

    await dossierServiceV2.getDossierDetailDefinition(detailDefinitionId)
      .then((def) => {
        if (def) {
          this.externalContext.setData("activityDefinition", activityDefinition);
          this.externalContext.setData("detailDefinitionId", detailDefinitionId);
          this.externalContext.setData("dossierdefinition", def);
        }
      });
    return;
  }

  showCreateDialog() {
    this.createNewFormDialogControl.show();
  }

  private createNewFormDialogControl!: FormDialogControl;
  recieveCreateNewFormDialogControl(control: FormDialogControl) {
    this.createNewFormDialogControl = control;
    this.createNewFormDialogControl.submitChanges = this.createNewCheckin;
    this.createNewFormDialogControl.transformBeforeSave = this.modifyNewCheckin;
    this.createNewFormDialogControl.submitDone = this.submitDone
  }

  async submitDone(){
    this.overviewControl?.refresh();
  }

  async createNewCheckin(form: DynamicForm, formData: any, refId?: string): Promise<boolean> {
    const referenceId = await checkinService.createCheckin(form, formData);
    if(this.createNewFormDialogControl){
      this.createNewFormDialogControl.setReferenceId(referenceId);
      this.createNewFormDialogControl.setReferenceType("checkin");
    }
    return true;
  }

  async modifyNewCheckin(form: DynamicForm, formData, referenceId, multiplicityReferenceId) {
  if(multiplicityReferenceId){
    const newDetailContext = new ExternalContext().inherit(form.externalContext);
    if (this.activityDefinition.checkins.regardingType === 'lot') {
      const lot = await dossierServiceV2.getLot(formData.selectdossier, multiplicityReferenceId);
      newDetailContext?.setData("lot", lot);
    }
    form.patchForm({
      regardingId: multiplicityReferenceId,
      referenceId: multiplicityReferenceId,
      regardingType: this.activityDefinition.checkins.regardingType,
      markExecutorsAsBusy: false,
      linkName: newDetailContext?.resolvePlaceholders(this.activityDefinition.checkins.linkName),
      linkPath: newDetailContext?.resolvePlaceholders(this.activityDefinition.checkins.linkPath)
    });
  }
  return true;
}

  private checkoutItem: any = null;
  public editPressed(overviewEvent: OverviewEvent) {
    if(overviewEvent.metadata.eventId === 'show'){
      this.checkoutItem = overviewEvent.item
      this.checkinDetailControl?.show(overviewEvent.item.id, overviewEvent.item);
      setTimeout(()=> {
        this.overviewControl?.sendEvent({
          type: "CLEAR_ACTION",
          item: null,
          metadata: {},
        });
      },2000);
      return;
    }
    if(overviewEvent.metadata.eventId === 'modify'){
      this.checkoutItem = overviewEvent.item
      this.checkinUploadFilesControl?.show(overviewEvent.item.id, overviewEvent.item);
      setTimeout(()=> {
        this.overviewControl?.sendEvent({
          type: "CLEAR_ACTION",
          item: null,
          metadata: {},
        });
      },2000);
      return;
    }else{
      this.checkoutItem = overviewEvent.item
      this.checkinCompleteControl?.show(overviewEvent.item.id, overviewEvent.item);
      setTimeout(()=> {
        this.overviewControl?.sendEvent({
          type: "CLEAR_ACTION",
          item: null,
          metadata: {},
        });
      },2000);
    }
  }

  private overviewControl: OverviewControl | null = null;
  public recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  protected checkinCompleteControl: FormDialogControl | null = null;

  protected recieveCheckinCompleteControl(control: FormDialogControl) {
    this.checkinCompleteControl = control;
    control.submitChanges = this.completeCheckin;
    control.afterSubmitChanges = this.setExternalEntity;
  }

  protected checkinDetailControl: FormDialogControl | null = null;
  protected recieveCheckinDetailsControl(control: FormDialogControl) {
    this.checkinDetailControl = control;
    control.afterSubmitChanges = this.setExternalEntity;
  }



  protected checkinUploadFilesControl: FormDialogControl | null = null;
  protected recieveCheckinUploadFilesControl(control: FormDialogControl) {
    this.checkinUploadFilesControl = control;
    control.submitChanges = this.modifyCheckin;
    control.afterSubmitChanges = this.setExternalEntity;
  }


  public async setExternalEntity(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    return true;
  }

  public modifyCheckin(
      form: DynamicForm,
      formData: any,
      referenceId?: string
  ): Promise<boolean> {
    form.patchForm({
      regardingType: this.activityDefinition.checkins.regardingType
    });
    return checkinService
        .updateCheckin(form, this.checkoutItem.id, form.formData, false)
        .then((value) => {
          this.checkoutItem = null;
          this.overviewControl?.refresh();
          return true;
        });
  }

  public completeCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    form.patchForm({
      actualEndDate: new Date(),
      regardingType: this.activityDefinition.checkins.regardingType
    });
    return checkinService
      .completeCheckin(form, this.checkoutItem.id, form.formData)
      .then((value) => {
        this.checkoutItem = null;
        this.overviewControl?.refresh();
        return true;
      });
  }
}
