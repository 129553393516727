






import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { validationRules } from "@/constants/ValidationRules";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextField from "@/components/common/TextField.vue";
import {scheduleService} from "@/app/services/schedule.service";
import FormComponentExternalEntityBase from "@/app/dynamic-components/forms/form-components/form-component-ext-entity.base";
import {Schedule} from "@/app/models/schedule/schedule.model";
import {RenderItem} from "@/app/Types";
import store from "@/store";

export type ScheduleMetaData = {
  blockType: string
  startDate: string
  endDate: string
  toState: string //move the schedule to a specific state if possible active pending cancelled
  blockSchedule: boolean //actually set the person as unavailable
  regardingId: string
  regardingType: string
  referenceId: string
  contactId: string
  organizationId: string
};

@Component({
  components: {},
})
export default class FormComponentSchedule extends FormComponentExternalEntityBase<ScheduleMetaData, Schedule> {


  protected async fetchExternalEntity(value: any): Promise<Schedule | null> {
    return scheduleService.getSchedule(value);
  }

  private creationIds: {[key: string]: string} = {};
  protected async createExternalEntity(formData: any): Promise<string> {
    const meta = this.entry.root?.resolvePlaceholders(this.entry.metadata);
    let startDate = meta.startDate && meta.startDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.startDate) : meta.startDate;
    startDate = meta.startDate && meta.startDate === 'now' ? new Date().toISOString() : startDate;
    let endDate = meta.endDate && meta.endDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.endDate) : meta.endDate;
    endDate = meta.endDate && meta.endDate === 'now' ? new Date().toISOString() : endDate;
    let contactIds = meta.contactId && meta.contactId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.contactId) : meta.contactId;
    const organizationId = meta.organizationId && meta.organizationId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.organizationId) : meta.organizationId;
    const referenceId = meta.referenceId && meta.referenceId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.referenceId) : meta.referenceId;
    const regardingType = meta.regardingType && meta.regardingType.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.regardingType) : meta.regardingType;
    if(startDate === '$nan') startDate = null;
    if(endDate === '$nan') endDate = null;
    if(typeof contactIds === 'string'){
      contactIds = [contactIds];
    }



    this.creationIds = {};
    for (const contactId of contactIds) {
      const scheduleId = await scheduleService.createSchedule({
        activityId: store.state.activity.currentActivityDefinition.id,
        startDate: startDate,
        endDate: endDate,
        description: '',
        state: 'pending', //active
        blockTime: meta.blockSchedule,
        blockType: meta.blockType,
        contactId: contactId,
        organizationId: organizationId,
        referenceId: referenceId,
        regardingType: regardingType
      });
      this.creationIds[contactId] = scheduleId;
    }

    return Object.values(this.creationIds).join(',');
  }

  protected async updateExternalEntity(externalEntityId: string, externalEntity: Schedule | null, formData: any): Promise<void> {
    if(!externalEntity ) return Promise.reject();

    const meta = this.entry.root?.resolvePlaceholders(this.entry.metadata);
    let toState = meta.toState && meta.toState.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.toState) : meta.toState;
    let startDate = meta.startDate && meta.startDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.startDate) : meta.startDate;
    startDate = meta.startDate && meta.startDate === 'now' ? new Date().toISOString() : startDate;
    let endDate = meta.endDate && meta.endDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.endDate) : meta.endDate;
    endDate = meta.endDate && meta.endDate === 'now' ? new Date().toISOString() : endDate;
    if(startDate === '$nan') startDate = null;
    if(endDate === '$nan') endDate = null;
    if(toState && (toState !== 'active' || toState !== 'pending' || toState !==  'cancelled')){
      toState = 'active'
    }

    const schedules = externalEntityId.split(',');
    for (const scheduleId of schedules) {
      await scheduleService.updateSchedule(scheduleId, {
        activityId: externalEntity.activityId,
        startDate: startDate,
        endDate: endDate,
        description: externalEntity.description,
        state: toState || externalEntity.state,
        blockTime: meta.blockTime,
        blockType: meta.blockType,
        contactId: externalEntity.contactId,
        organizationId: externalEntity.organizationId,
        referenceId: externalEntity.referenceId,
        regardingType: externalEntity.regardingType,
        regardingId: externalEntity.regardingType ? externalEntity.regardingId : undefined
      });
    }
    return Promise.resolve();
  }


  protected async createExternalEntityAfterSave(sumbitData: any): Promise<void> {
    if(!this.creationIds || Object.keys(this.creationIds).length <=0) return;

    //link to other entity needs to happen after safe + status to active to active the active the schedule

    const meta = this.entry.root?.resolvePlaceholders(this.entry.metadata);
    let toState = meta.toState && meta.toState.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.toState) : meta.toState;
    let startDate = meta.startDate && meta.startDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.startDate) : meta.startDate;
    startDate = meta.startDate && meta.startDate === 'now' ? new Date().toISOString() : startDate;
    let endDate = meta.endDate && meta.endDate.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.endDate) : meta.endDate;
    endDate = meta.endDate && meta.endDate === 'now' ? new Date().toISOString() : endDate;
    let contactIds = meta.contactId && meta.contactId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.contactId) : meta.contactId;
    const organizationId = meta.organizationId && meta.organizationId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.organizationId) : meta.organizationId;
    const referenceId = meta.referenceId && meta.referenceId.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.referenceId) : meta.referenceId;
    const regardingType = meta.regardingType && meta.regardingType.startsWith('data.') ? this.entry.root?.resolveDataPath(meta.regardingType) : meta.regardingType;
    if(startDate === '$nan') startDate = null;
    if(endDate === '$nan') endDate = null;
    if(typeof contactIds === 'string'){
      contactIds = [contactIds];
    }
    if(toState && (toState !== 'active' || toState !== 'pending' || toState !==  'cancelled')){
      toState = 'active'
    }

    for (const contactId of contactIds) {
      const scheduleId = this.creationIds[contactId];
      await scheduleService.updateSchedule(scheduleId, {
        activityId: store.state.activity.currentActivityDefinition.id,
        startDate: startDate,
        endDate: endDate,
        description: '',
        state: toState? toState : 'active', //active
        blockTime: meta.blockTime,
        blockType: meta.blockType,
        contactId: contactId,
        organizationId: organizationId,
        referenceId: referenceId,
        regardingType: regardingType,
        regardingId: sumbitData?.referenceId.split(',')[0] || null
      });
    }

    this.creationIds = {};
    return Promise.resolve();
  }
}
