import { DossierCreationModel } from "@/app/models/dossier/creation/dossier-creation.model";
import { DossierCreationDefinitionMock } from "@/app/mock/dossier-creation-definition.mock";
import {
  dossierDetailDataMock,
  dossierDetailDefinitionMock,
} from "@/app/mock/dossier-detail.mock";
import axios from "@/plugins/axios";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { DossierEditModel } from "@/app/models/dossier/edit/dossier-edit-model";
import { dossierEditDefinitionMock } from "@/app/mock/dossier-edit-definition-mock";
import { DynamicDetailDefinition } from "@/app/dynamic-components/details/dynamic-detail.model";
import { overviewDataMock, overviewMock } from "@/app/mock/overview.mock";
import { dossierMapper } from "@/app/mappers/dossier.mapper";
import {
  DynamicForm,
  DynamicFormEntry, IndexList,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import {
  CreateLotRequest,
  LotModel,
  UpdateLotRequest,
} from "@/app/models/lot/lot.model";
import { Overview } from "@/app/models/overview/overview.model";
import { overviewService } from "@/app/services/overview.service";
import store from "@/store";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import { DetailContext } from "@/app/contexts/detail.context";

class DossierService {

  private definitionCache: {[key: string]: any} = {}
  getDossierCreationDefinition(
    dossierCreationDefinitionId: string
  ): Promise<DossierCreationModel> {
    if(this.definitionCache[dossierCreationDefinitionId]) return Promise.resolve(this.definitionCache[dossierCreationDefinitionId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/definition/creation/${dossierCreationDefinitionId}`;
    return axios.get<DossierCreationModel>(url).then((response) => {
      if(response.status === 200){
        this.definitionCache[dossierCreationDefinitionId] = response?.data;
        return response?.data;
      }else{
        return Promise.reject('Could not load definition');
      }
    });
    //return Promise.resolve(DossierCreationDefinitionMock[dossierCreationDefinitionId]);
  }

  getDossierDetailDefinition(
    dossierDetailDefinitionId: string
  ): Promise<DynamicDetailDefinition> {
    if(this.definitionCache[dossierDetailDefinitionId]) return Promise.resolve(this.definitionCache[dossierDetailDefinitionId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/definition/details/${dossierDetailDefinitionId}`;
    //return Promise.resolve(dossierDetailDefinitionMock[dossierDetailDefinitionId]);
    return axios.get<DynamicDetailDefinition>(url).then((response) => {
      if(response.status === 200){
        this.definitionCache[dossierDetailDefinitionId] = response?.data;
        return response?.data;
      }else{
        return Promise.reject('Could not load definition');
      }
    });
  }

  getDossierEditDefinition(
    dossierModifyDefinitionId: string
  ): Promise<DossierEditModel> {
    if(this.definitionCache[dossierModifyDefinitionId]) return Promise.resolve(this.definitionCache[dossierModifyDefinitionId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/definition/modify/${dossierModifyDefinitionId}`;
    //return Promise.resolve(dossierEditDefinitionMock[dossierModifyDefinitionId]);
    return axios.get<DossierEditModel>(url).then((response) => {
      if(response.status === 200){
        this.definitionCache[dossierModifyDefinitionId] = response?.data;
        return response?.data;
      }else{
        return Promise.reject('Could not load definition');
      }
    });
  }

  getDossierData(dossierId: string): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}`;

    return axios.get(url).then((response) => {
      return dossierMapper.mapFromApi(response.data);
    });
  }

  public async createNewDossier(
      formData: any,
      dossierDefinitionId: string,
      model: DynamicForm
  ) {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers`;
    const dossierData = dossierMapper.mapToApi(
        formData,
        model,
        dossierDefinitionId
    );
    dossierData["status"] = "In Progress";

    const indexers = new IndexList();
    await model.resolveIndexers(indexers);
    dossierData['indexers'] = indexers;

    return axios.post(url, dossierData);
  }

  public async updateDossier(
    formData: any,
    dossierDefinitionId: string,
    dossierId: string,
    model: DynamicForm,
    status: string, hasView: boolean
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}`;
    const dossierData = dossierMapper.mapToApi(
      formData,
      model,
      dossierDefinitionId
    );
    dossierData["id"] = dossierId;
    dossierData["status"] = status;

    if(hasView){
      const indexers = new IndexList();
      await model.resolveIndexers(indexers);
      dossierData['indexers'] = indexers;
    }else{
      //if no view we can only copy details from old object
      const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}`;
      const originalData = (await axios.get(url))?.data;
      if(originalData?.indexers){
        dossierData['indexers'] = originalData.indexers;
      }
    }

    return await axios.put(url, dossierData);
  }

  public updateDossierStatus(detailsContext: DetailContext, status: string) {
    return this.getDossierEditDefinition(
      detailsContext.data.activity.dossiers.entries[0].dossierModifyDefinitionId
    ).then((response) => {
      const form: DynamicForm = DynamicForm.BUILD_FROM_JSON_DEFINITION(
        response.form
      );
      form?.patchForm(detailsContext.data.dossier);
      return this.updateDossier(
        detailsContext.data.dossier,
        detailsContext.definitionId,
        detailsContext.data.dossierId,
        form,
        status, false
      );
    });
  }

  async getFirstDossier(): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers?page=0&pageSize=1&sort=`;

    return axios.get(url).then((response) => {
      return response.data;
    });
  }

  async addPartyToDossier(
    referenceId: string,
    contactType: string,
    contact: string,
    roleId: string
  ) {
    if(!contact) return Promise.resolve();
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${referenceId}/addParty`;

    return axios
      .post(url, {
        contactId: contact,
        //roleId: roleId,
        portalId: contact,
        contactType: contactType,
      })
      .then((response) => {
        return response.data;
      });
  }

  async getLots(dossierId: string): Promise<Overview> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}/lots?page=0&pageSize=100&sort=createdon,asc`;
    return axios.get<Overview>(url).then((response) => {
      if (response?.data?._embedded?.results) {
        response.data._embedded.results = Array.from(
          response.data._embedded.results
        ).map((value) => this.convertGetLotToLot(value));
      }
      return response.data;
    });
  }

  async getLot(dossierId: string, lotId: string): Promise<LotModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}/lots/${lotId}`;
    return axios.get(url).then((response) => {
      return this.convertGetLotToLot(response.data);
    });
  }

  async createLot(
    form: DynamicForm,
    dossierId: string,
    create: any
  ): Promise<LotModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}/lots`;
    const createRequest = await this.convertFormDataToCreateLotRequest(form, create);
    createRequest.dossierId = dossierId;
    return axios.post(url, createRequest).then((response) => {
      return this.convertGetLotToLot(response.data);
    });
  }

  async updateLot(
    form: DynamicForm,
    dossierId: string,
    lotId: string,
    update: any,
    status: string | null
  ): Promise<LotModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}/lots/${lotId}`;
    const updateRequest = await this.convertFormDataToUpdateLotRequest(form, update);
    updateRequest.dossierId = dossierId;
    updateRequest.status = status || "CREATED" as any;
    return axios.put(url, updateRequest).then((response) => {
      return this.convertGetLotToLot(response.data);
    });
  }

  async deleteLot(
    form: DynamicForm,
    dossierId: string,
    lotId: string,
    update: any
  ): Promise<LotModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/dossiers/${dossierId}/lots/${lotId}`;
    const updateRequest = await this.convertFormDataToUpdateLotRequest(form, update);
    updateRequest.dossierId = dossierId;
    updateRequest.status = "SCRAPPED";
    return axios.put(url, updateRequest).then((response) => {
      return this.convertGetLotToLot(response.data);
    });
  }

  public convertGetLotToLot(lot: any): LotModel {
    lot = { ...lot, ...lot.dynamic_Properties };
    lot.status = lot.status?.toUpperCase();
    return lot;
  }

  private async convertFormDataToUpdateLotRequest(
      form: DynamicForm,
      formdata: any
  ): Promise<UpdateLotRequest> {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    await form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }

  private async convertFormDataToCreateLotRequest(
      form: DynamicForm,
      formdata: any
  ): Promise<CreateLotRequest> {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.status = "ACTIVE";
    result.dynamic_Properties = mappedValues.dynamicProperties;

    const indexers = new IndexList();
    await form.resolveIndexers(indexers);
    result['indexers'] = indexers;

    return result;
  }
}

export const dossierServiceV2 = new DossierService();
