import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { Vue } from "vue-property-decorator";
import router from "@/router";

export default class CompleteDossierEvent extends EventBase {
    async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
        if(!(context instanceof DetailContext)) return Promise.reject('Context is not a detailcontext');
        const detailsContext: DetailContext = context;

    dossierServiceV2
      .updateDossierStatus(detailsContext, "Done")
      .then(() => {
        Vue.$toast.success("Successfully saved.");
        router.back();
      })
      .catch(() => {
        Vue.$toast.error("Something went wrong.");
      });
  }
}
