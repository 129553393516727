import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import { DetailComponentBase } from "@/app/dynamic-components/details/detail-component.base";
import {Store} from "vuex";
import Vue from "vue";

export interface ContextAware {
  resolvePlaceholders(data: any): any;
  resolveDataPath(dataPath: string): any;
  getData(): { [key: string]: any };
}

export class DetailContext implements ContextAware {
  public data: { [key: string]: any } = {};
  public definitionId = "";
  public root: DetailComponentBase | undefined = undefined;

  public setData(key: string, object: any) {
    if (key.startsWith("data.")) key = key.replace("data.", "");
    this.data[key] = object;
  }

  public resolvePlaceholders(data: any): any {
    if(!data) return data;
    if (typeof data === "string") {
      return this.resolvePlaceholdersString(data);
    }
    return JSON.parse(this.resolvePlaceholdersString(JSON.stringify(data)));
  }

  private resolvePlaceholdersString(str: any): any {
    const calculatedReplaceRegex = /\${([^$}]*)}/;

    let replaceRegex;
    let c = 0;
    while ((replaceRegex = calculatedReplaceRegex.exec(str)) && c < 1000) {
      const fallbackorder = replaceRegex[1].split(';');
      let replaced = false;
      for (let i = 0; i < fallbackorder.length && !replaced; i++) {
        const placeholder = fallbackorder[i];

        const value = this.resolveDataPath(placeholder);

        if(value){
          str = str.replaceAll(replaceRegex[0], value ? value : "$nan");
          //console.log("found regex", JSON.stringify(replaceRegex), value, str);
          replaced = true;
        }else{
          //console.log("found regex no match", JSON.stringify(replaceRegex), value, str);
        }
      }
      if(!replaced){
        str = str.replaceAll(replaceRegex[0], "$nan");
        //console.log("found regex", JSON.stringify(replaceRegex), "$nan", str);
      }
      //console.log('found regex', JSON.stringify(replaceRegex), value,str);
      c++;
    }
    return str;
  }

  public resolveDataPath(dataPath: string): any {
    if (!dataPath) return null;
    if (dataPath.startsWith("data.")) dataPath = dataPath.replace("data.", "");
    return getObjectContentsFromPath(dataPath, this.data);
  }

  setRoot(root: DetailComponentBase, definitionId: string) {
    this.root = root;
    this.definitionId = definitionId;
  }

  Clone(): DetailContext {
    const d = new DetailContext();
    if (this.root) {
      d.setRoot(this.root, this.definitionId);
    }
    d.data = JSON.parse(JSON.stringify(this.data));
    return d;
  }

  getData(): { [p: string]: any } {
    return this.data;
  }
}
