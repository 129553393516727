import { DossierCreationModel } from "@/app/models/dossier/creation/dossier-creation.model";
import axios from "@/plugins/axios";
import { ExchangeDefinition } from "@/app/dynamic-components/details/dynamic-detail.model";
import { exchangeMock, exhangeDefintionsMock } from "@/app/mock/exchange.mock";
import {
  Exchange,
  ExchangeStep,
} from "@/app/components/exchange/exchange.model";
import { convertTypeToApiType } from "@/app/helpers/api.helper";
import store from "@/store";

type FinalObjType = {
  involvedParties: InvolvedParty[];
  steps: ApiStep[];
  regardingId: string;
  regardingType: string;
  exchangeDefinitionId: string;
  name: string;
  state: string;
};

type FinalMoveObjType = {
  dynamic_Properties: any;
  definitionId: string;
  id: string;
  name: string;
  state: string;
};

type InvolvedParty = {
  portalId: string;
  contactId: string;
  roleId: string;
  contactType: string;
};

type ApiStep = {
  definitionId: string;
  state: "INPROGRESS" | "COMPLETED" | "SKIPPED" | "TODO";
  dynamic_Properties: any;
  name: string;
};

class ExchangeService {

  private definitionCache: {[key: string]: any} = {}
  getExchangeDefinition(exchangeDefinitionId: string): Promise<ExchangeDefinition> {
    if(this.definitionCache[exchangeDefinitionId]) return Promise.resolve(this.definitionCache[exchangeDefinitionId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges/definition/${exchangeDefinitionId}`;
    return axios.get<ExchangeDefinition>(url).then((response) => {
      if (response?.data && !response.data.type) response.data.type = "STEPPER";
      if(response.status === 200){
        this.definitionCache[exchangeDefinitionId] = response?.data;
        return response?.data;
      }else{
        return Promise.reject('Could not load definition');
      }
    });
    // return Promise.resolve(exhangeDefintionsMock[exchangeDefinitionId]);
  }

  getExchange(exchangeId: string): Promise<Exchange> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges/${exchangeId}`;
    return axios.get<Exchange>(url).then((response) => {
      return this.convertApiExchangeToExchange(response?.data);
    });
    // return Promise.resolve(exchangeMock[exchangeId]);
  }

  createExchange(
    exchange: Exchange,
    formData: any,
    exchangeDefinition: ExchangeDefinition,
    referenceId: string | null,
    referenceType: string | null
  ): Promise<Exchange> {
    if (!referenceId || !referenceType)
      return Promise.reject("reference not correctly set");
    referenceType = convertTypeToApiType(referenceType);

    // send api call
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges/`;
    const finalObj: FinalObjType = {
      involvedParties: [],
      steps: [],
      regardingId: referenceId,
      regardingType: referenceType,
      exchangeDefinitionId: exchange.exchangeDefinitionId,
      name: exchangeDefinition.title,
      state: "INPROGRESS",
    };
    exchange.involvedParties.forEach((involvedParty) => {
      finalObj["involvedParties"].push({
        portalId: involvedParty.id,
        contactId: involvedParty.contactId,
        roleId: involvedParty.roleId,
        contactType: involvedParty.contactType,
      });
    });
    exchange.steps.forEach((step, index) => {
      finalObj["steps"].push({
        definitionId: step.id,
        state: step.state,
        dynamic_Properties: step.data,
        name: exchangeDefinition.steps[index].name,
      });
    });

    return axios.post(url, finalObj).then((response) => {
      return this.convertApiExchangeToExchange(response.data);
    });
  }

  public convertApiExchangeToExchange(data: any): Exchange {
    data.state = data?.state?.toUpperCase();
    //data.state = "COMPLETED";
    data?.involvedParties?.forEach((involvedParty) => {
      involvedParty.logicId = involvedParty.id;
      involvedParty.id = involvedParty.portalId;
      involvedParty.contactType = involvedParty?.contactType?.toUpperCase();
    });
    data?.steps?.forEach((step) => {
      step.stepId = step.id;
      step.id = step.definitionId;
      step.state = step.state.toUpperCase();
      step.data = step.dynamic_Properties;
    });
    return data;
  }

  async moveExchange(
    exchange: Exchange,
    formData: any,
    forward: boolean
  ): Promise<Exchange> {
    const index = exchange.steps.findIndex(
      (value) => value.id === exchange.currentStep
    );
    if (index < 0) return Promise.reject("step not found");

    //if created AND in progress & TODO: if right to edit then save

    if (exchange.steps[index].state == "INPROGRESS") {
      const finalMoveObj = {
        action: forward ? "Confirm" : "Reject_Last",
        step: {
          id: exchange.steps[index].stepId,
          definitionId: exchange.steps[index].id,
          dynamic_Properties: formData,
          name: exchange.steps[index].id,
          state: forward ? "COMPLETED" : "TODO",
        },
      };

      const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges/${exchange.id}`;
      return axios.put(url, finalMoveObj).then((response) => {
        exchange = response.data;
        return this.convertApiExchangeToExchange(exchange);
      });
    } else {
      return Promise.reject("No in progress step found");
    }

    //mock
    /*
      exchange.steps[index].data = formData;
      exchange.steps[index].state = "COMPLETED";
      if (exchange.steps.length > index + 1) {
        exchange.steps[index + 1].state = "INPROGRESS";
        exchange.currentStep = exchange.steps[index + 1].id;
      } else {
        exchange.state = "COMPLETED";
      }
      return Promise.resolve(exchange);
     */
  }

  completeExchange(exchange: Exchange, formData: any): Promise<Exchange> {
    return this.moveExchange(exchange, formData, true);
  }

  async getFirstExchange(
    exchangeDefinitionId: string,
    referenceId: string
  ): Promise<Exchange | null> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges?definitionId=${exchangeDefinitionId}&referenceId=${referenceId}&page=0&pageSize=1&sort=`;

    return axios
      .get(url)
      .then((response) => {
        if (
          !response?.data?._embedded?.results ||
          !Array.isArray(response?.data?._embedded?.results) ||
          Array.from(response?.data?._embedded?.results).length <= 0
        )
          return null;
        const exchange = response.data._embedded.results[0];
        return this.convertApiExchangeToExchange(exchange);
      })
      .catch((reason) => {
        return null;
      });
  }

  patchExchange(exchange: Exchange, formData: any, stepDefinitionId: string): Promise<Exchange> {
    const index = exchange.steps.findIndex(
      (value) => value.id === stepDefinitionId
    );
    if (index < 0) return Promise.reject("step not found");

    //if created AND in progress & TODO: if right to edit then save
    const step = exchange.steps[index];

    const finalMoveObj = {
      step: {
        id: step.stepId,
        definitionId: step.id,
        dynamic_Properties: formData,
        name: step.id,
        state: step.state,
      },
    };

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/exchanges/${exchange.id}`;
    return axios.patch(url, finalMoveObj).then((response) => {
      exchange = response.data;
      return this.convertApiExchangeToExchange(exchange);
    });
  }
}

export const exchangeService = new ExchangeService();
