










































































import { Component } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import { Overview, OverviewPage } from "@/app/models/overview/overview.model";
import { deepMergeObjects } from "@/app/helpers/stringpath.helper";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import { EventDictionary } from "@/app/events/event.dictionary";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { formService } from "@/app/services/form.service";
import { OverviewControl } from "@/app/dynamic-components/overviews/overiew.model";

export interface OverviewWidgetMetaData extends WidgetMetaData {
  overviewDefinitionId: string;
  entityType: string;
  tableStyle: string;
  path: string;
  itemDisplayLimit: number;
  rowClickEvent: string;
  route: string;
  showDialogOnRowClick: boolean;
  dialogFormDefinitionId: string;
  dialogTitle: string;
  dialogDeleteEvent: string;
  dialogUpdateEvent: string;
  referenceId: string;
}

@Component({
  components: { FormWrapper, OverviewComponent },
})
export default class WidgetComponentOverview extends DetailComponentBase<OverviewWidgetMetaData> {
  public checklist: {
    label: string;
    id: string;
    present: boolean;
  }[] = [];
  public dialogFormDefinition = {} as any;
  showWidgetDialog = false;
  eventLoading = false;
  formView: DynamicForm | undefined;
  private _overviewControl: OverviewControl | undefined;
  externalContext: ExternalContext = new ExternalContext();
  additionalMetadata: { [key: string]: any } = {};

  async mounted(): Promise<void> {
    if (this.metadata.dialogFormDefinitionId) {
      this.dialogFormDefinition = await this.resolveDialogFormDefinition(
        this.metadata.dialogFormDefinitionId
      );
    }
  }

  mapToOverviewData: (data: Overview) => Overview = (data: Overview) => {
    return {
      _embedded: {
        results: data._embedded.results.map((result) => {
          return {
            ...result,
            ...{
              data: deepMergeObjects(result.data, result["dynamic_Properties"]),
              id: result.id,
              createdOn: result.createdOn,
              lastModifiedOn: result.lastModifiedOn,
            },
          };
        }),
      },
      _filter: data._filter,
      _page: this.setItemDisplayLimit(data._page),
      _sort: data._sort,
    };
  };

  async postEntityChanged(newValue: DynamicDetailEntry) {
    if (newValue) {
      if(newValue.metadata?.types){
        this.checklist = Object.entries(newValue.metadata.types).map((entry) => {
          return { label: entry[1] as string, id: entry[0], present: false };
        });
      }else{
        this.checklist = [];
      }
      this.externalContext.setData("types", this.checklist);
      this.externalContext.setData("translate", newValue.metadata.translate);
      this.additionalMetadata.types = this.checklist;
      this.additionalMetadata.translated = newValue.metadata.translate;
    }
  }

  setItemDisplayLimit(page: OverviewPage): OverviewPage {
    page.size = this.metadata.itemDisplayLimit;
    return page;
  }

  rowClicked(item: { item: any; index: number }): void {
    if (this.metadata.showDialogOnRowClick) {
      this.showWidgetDialog = !this.showWidgetDialog;

      if (this.showWidgetDialog) {
        this.formView?.patchForm(item?.item);
      } else {
        this.formView?.reset();
      }
    } else {
      EventDictionary[this.metadata.rowClickEvent]?.getCallBack(
        this.detailContext,
        {
          metadata: this.metadata,
          item: item,
        },
        undefined
      );
    }
  }

  async resolveDialogFormDefinition(
    dialogFormDefinitionId: string
  ): Promise<any> {
    return await formService.getDialogDefinition(dialogFormDefinitionId);
  }

  async resolveEvent(eventName: string): Promise<void> {
    this.eventLoading = true;

    const success = await EventDictionary[eventName]?.getCallBack(
      this.detailContext,
      {
        formView: this.formView,
      },
      undefined
    );

    if (success) {
      this.formView?.reset();
      this._overviewControl?.refresh();
      this.showWidgetDialog = false;
    }

    this.eventLoading = false;
  }

  recieveFormView(form: DynamicForm): void {
    this.formView = form;
  }

  recieveOverviewControl(overviewControl: OverviewControl) {
    this._overviewControl = overviewControl;
  }
}
