

































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { EventDictionary } from "@/app/events/event.dictionary";
import { DetailContext } from "@/app/contexts/detail.context";
import {
  DynamicDetailDefinition,
  DynamicDetailEntry,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import Loader from "@/components/common/Loader.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import router from "@/router";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DetailComponentBase } from "@/app/dynamic-components/details/detail-component.base";
import IamRole from "@/contracts/iam/IamRole";
import ExtendedActivityDto from "@/contracts/activity/ExtendedActivityDto";
import store from "@/store";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {FormDialogControl} from "@/app/components/dialog/form-dialog.model";
import {DynamicForm} from "@/app/dynamic-components/forms/dynamic-form.model";

@Component({
  components: {FormDialogComponent, ExchangeComponent, PageHeader, Loader },
})
export default class DossierDetailComponent extends DetailComponentBase {
  get attrs() {
    return {
      boilerplate: false,
    };
  }

  genericContext: DetailContext | null = null;
  genericDefinitionId: string | null = null;
  genericControl: FormDialogControl | null = null;
  public recieveGenericDialogComponent(control: FormDialogControl){
    this.genericControl = control;
  }

  setModal(dialogDefinitionId: string, referenceId: string, genericContext: DetailContext, initFormData: any,
           submitChanges: (form: DynamicForm, formData: any, referenceId?: string) => Promise<boolean>,
           destroyItem: (form: DynamicForm, formData: any, referenceId: string) => Promise<boolean>,
           transformBeforeSave: (form: DynamicForm, formData: any, formReferenceId: string | undefined, multiplicityReferenceId: string | undefined) => Promise<boolean>) {
    if(!this.genericControl) return;

    this.genericDefinitionId = dialogDefinitionId;
    this.genericContext = genericContext;
    this.genericControl.submitChanges = submitChanges;
    this.genericControl.destroyItem = destroyItem;
    this.genericControl.transformBeforeSave = transformBeforeSave;
    setTimeout(()=>{
      if(!this.genericControl) return;
      this.genericControl.show(referenceId, initFormData)
    }, 500);
  }

  closeModal() {
    this.genericControl?.close();
  }

  getModal(): FormDialogControl | null{
    return this.genericControl;
  }


  protected getDetailDefinition(definitionId: string): Promise<DynamicDetailDefinition> {
    return dossierServiceV2.getDossierDetailDefinition(definitionId)
  }

  protected getDetailData(): Promise<any> {
    return dossierServiceV2.getDossierData(this.$route.params.id);
  }


  protected setDetailContext(data: any, detailDefinition: DynamicDetailDefinition | null) {
    this.detailContext.setData("activity", this.$store.state.activity.currentActivityDefinition);
    const user = this.$store.state.user;
    this.detailContext.setData("user", {
      contactId: user.contactId,
      organizationId: user.organizationId,
      language: user.language,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.activityRoles,
      roleIds: user.activityRoleIds
    });
    this.detailContext.setData("bccaId", store.state.appConfig.bccaId);
    this.detailContext.setData("dossierEditDefinitionId", this.$store.state.activity.currentActivityDefinition.dossiers.entries[0].dossierModifyDefinitionId);
    this.detailContext.setData("dossier", data.data);
    this.detailContext.setData("dossierStatus", data.status);
    this.detailContext.setData("dossierId", this.$route.params.id);
    this.detailContext.setData("dossierSecurity", this.getDossierSecurity(this.detailDefinitionId));
  }

  goBack() {
    router.back();
  }


}
