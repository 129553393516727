import store from "@/store";
import { RoutePaths } from "@/router";
import IamRole from "@/contracts/iam/IamRole";
import { NavigationGuardNext, Route } from "vue-router";
import { authenticationService } from "@/services/authentication.service";
import { authRoles } from "@/constants/AuthRoles";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { ActivitySecurity } from "@/app/models/activity/security.model";

class AuthorizationService {
  userIsAuthorized(authorizedRoles: string[]): boolean {
    const currentUserRoles: IamRole[] = store.state.user.roles;

    if (!authorizedRoles || authorizedRoles.length == 0) {
      return true;
    }

    if (!currentUserRoles) {
      return false;
    }

    return (
      currentUserRoles.find((role) => authorizedRoles.includes(role.name)) !==
      undefined
    );
  }

  userIsAuthorizedByBusinessRules(rules: (() => boolean)[]): boolean {
    if (!rules || rules.length == 0) {
      return true;
    }

    for (let i = 0; i < rules.length; i++) {
      if (!rules[i]()) {
        return false;
      }
    }

    return true;
  }

  async handleRoleBasedNavigation(to: Route, next: NavigationGuardNext) {
    const { authorizedRoles } = to.meta || {};
    if (authorizedRoles && authorizedRoles.length) {
        // check if route is restricted by role
        if (this.userIsAuthorized(authorizedRoles)) {
          next();
        }
    } else {
      next();
    }
  }

  async handleBusinessRulesBasedNavigation(
    to: Route,
    next: NavigationGuardNext
  ) {
    const { authorizationBusinessRules } = to.meta || {};

    if (authorizationBusinessRules && authorizationBusinessRules.length) {
      if (this.userIsAuthorizedByBusinessRules(authorizationBusinessRules)) {
        next();
      } else {
        await this.navigateToLandingPage(next);
      }
    } else {
      next();
    }
  }

  async navigateToLandingPage(next: NavigationGuardNext) {
    const isLoggedIn = store.state.user.roles && store.state.user.roles.length;
    if (isLoggedIn) {
      this.navigateToLandingPageDo(next);
    } else {
      await authenticationService
        .acquireToken()
        .then(() => {
          this.navigateToLandingPageDo(next);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async navigateToLandingPageDo(next: NavigationGuardNext){
    const landingPage = this.getLandingPage();
    if (landingPage) {
      next(landingPage);
    } else {
      authenticationService.signIn();
    }
  }

  private getLandingPage() {
    const security = store.state.activity.currentActivitySecurity as ActivitySecurity;
    if(security?.home && Object.keys(security?.home).length > 0){
      const roles = store.state.user.activityRoles as IamRole[];
      //let roles = store.state.user.roles as IamRole[];
      if(roles && roles.length > 0){
        for (const role of roles) {
          if(security?.home[role.id]){
            return security?.home[role.id];
          }
        }
        if(security?.home["default"]){
          return security?.home["default"];
        }
        return RoutePaths.MyDossiers;
      }else{
        //force signin
        return;
      }
    }else{
      //legacy system
      if (
        this.userIsAuthorized([authRoles.ORGANIZATION_ADMIN, authRoles.EXECUTOR])
      ) {
        return RoutePaths.Dashboard;
      } else if (this.userIsAuthorized([authRoles.INSPECTOR])) {
        return RoutePaths.MyDossiers;
      }
    }
  }
}

export const authorizationService = new AuthorizationService();
