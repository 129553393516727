import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import { documentServiceV2 } from "@/app/services/document.service";
import { Vue } from "vue-property-decorator";

export default class DeleteDocumentEvent extends EventBase {
  async getCallBack(context: ContextAware, payload?: any): Promise<any> {
    if (!payload.formView) return;
    const item = payload.formView.formData;
    if (!item?.id) return;

    return await documentServiceV2.deleteDocumentById(item.id);
  }
}
