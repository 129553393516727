import FormComponentTreeCheckbox from "@/app/dynamic-components/forms/form-components/components/form-component-tree-checkbox.vue";

interface ComponentDictionaryInterface {
  [key: string]: string;
}

export class ComponentTypeDictionaryService {
  static getComponentFromDictionary = (componentString: string): string => {
    const ComponentTypeDictionary: ComponentDictionaryInterface = {
      TextField: "TextFieldBase",
      AddressForm: "AddressFormBase",
      TestSection: "TestSection",
      ActivityDomainForm: "ActivityDomainForm",
      PlanningForm: "PlanningForm",
      Header: "FormComponentHeader",
      SelectOrg: "FormComponentSelectOrgInRole",
      SelectPerson: "FormComponentSelectPersonInRole",
      Lots: "FormComponentLots",
      TextArea: "TextAreaBase",
      Acordeon: "Acordeon",
      LinearSection: "LinearSection",
      Select: "SelectBase",
      Selectv2: "DynamicSelect",
      FileInput: "FileInputBase",
      ContactDetailsForm: "ContactDetailsFormBase",
      CheckBox: "CheckBoxBase",
      RadioButton: "RadioButtonBase",
      Switch: "SwitchBase",
      Slider: "SliderBase",
      DatePicker: "DatePickerBase",
      TimePicker: "TimePickerBase",
      DateTimePicker: "DateTimePickerBase",
      Planner: "PlannerBase",
      SelectOverviewItem: "DynamicSelectFromOverview",
      SelectEmbeddedList: "DynamicSelectFromEmbedded",
      ExtraText: "FormComponentText",
      FormComponentMulticheckinSelector: "FormComponentMulticheckinSelector",
      SelectFromOverview: "FormComponentOverviewSelection",
      Checkin: "FormComponentCheckin",
      SelectOrgPerson: "FormComponentSelectOrgAndUserInRoles",
      LinearSectionMultiplicity: "FormSectionLinearMultiplicity",
      TreeCheckBox: "FormComponentTreeCheckbox",
      Combobox: "DynamicCombobox",
      Schedule: "FormComponentSchedule",
    };

    const result: string = ComponentTypeDictionary[componentString];

    if (!result) {
      console.error(`Component ${componentString} not found in dictionary`);
      return "";
    }
    return result;
  };
}
