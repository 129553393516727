









































import { Component } from "vue-property-decorator";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { defaultDynamicFormEntry } from "@/app/dynamic-components/dynamic-components.model";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { RenderItem, RenderType } from "@/app/Types";
import {
  OverviewControl,
  OverviewEvent,
} from "@/app/dynamic-components/overviews/overiew.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import Loader from "@/components/common/Loader.vue";
import { DetailContext } from "@/app/contexts/detail.context";
import { ExternalContext } from "@/app/contexts/externalContext";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { Overview } from "@/app/models/overview/overview.model";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import { planningService } from "@/app/services/planning.service";
import store from "@/store";

export type DetailModifyEntitiesDialogMetaData = {
  lotOverviewDefinitionId: string;
  createNewLotDialogDefinitionId: string;
  modifyLotDialogDefinitionId: string;
  addAdditionalOrganisationsToParty: Array<string>;
  addAdditionalUsersToParty: Array<string>;
  selector: string;
  dialogWidth: string;
};

@Component({
  computed: {},
  components: { FormDialogComponent, Loader, OverviewComponent, FormWrapper },
})
export default class ModifyEntitiesDialog extends DetailComponentBase<DetailModifyEntitiesDialogMetaData> {
  path: string | null = null;

  formDefinition: DynamicDetailEntry = defaultDynamicFormEntry;
  mode: "OVERVIEW" | "MODIFY" = "OVERVIEW";
  isSaving = false;

  externalContext: ExternalContext = new ExternalContext()

  private items: any[] = [];

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (
      this.entry &&
      this.entry.children &&
      Array.from(this.entry.children).length === 1
    ) {
      this.formDefinition = this.entry.children[0];
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.detailContext) {
      this.externalContext = this.externalContext.inherit(this.detailContext)
      const dossierId = this.detailContext.data.dossierId;
      this.createNewcontrol?.setReferenceId(dossierId);
      this.modifyLotcontrol?.setReferenceId(dossierId);
      this.externalContext.setData("dossierId", dossierId);
      this.externalContext.setData("dossier", this.detailContext.data.dossier);
      this.path = `api/v2/dossiers/${dossierId}/lots`;

      const items = this.detailContext.resolveDataPath(this.metadata.selector);
      this.items = items;
    }
  }

  overviewControl: OverviewControl | null = null;
  protected recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  mapToOverviewData(overview: Overview): Overview {
    overview._embedded.results = Array.from(
      overview?._embedded?.results || []
    ).map((value) => dossierServiceV2.convertGetLotToLot(value));
    return overview;
  }

  openCreateLotDialog() {
    this.updatingLotId = null;
    this.updatingLotInitData = null;
    this.externalContext.setData("id", null);
    this.externalContext.setData("type", null);
    this.createNewcontrol?.show();
  }

  private updatingLotId: string | null = null;
  private updatingLotInitData: any | null = null;
  openUpdateLotDialog(item: { item: any; index: number }) {
    this.updatingLotId = item.item.id;
    this.updatingLotInitData = item.item;
    this.externalContext.setData("id", item.item.id);
    this.externalContext.setData("type", "lot");
    this.modifyLotcontrol?.show(item.item.id, item.item);
  }

  closeDialog() {
    if(this.detailContext?.root instanceof DossierDetailComponent){
      this.detailContext?.root?.closeModal();
      this.detailContext?.root?.getData();
    }
  }

  async createNewLot(form: DynamicForm, formData: any): Promise<boolean> {
    return dossierServiceV2
      .createLot(form, this.detailContext.data.dossierId, formData)
      .then((value) => {
        if(this.metadata.addAdditionalOrganisationsToParty && Array.isArray(this.metadata.addAdditionalOrganisationsToParty) && this.metadata.addAdditionalOrganisationsToParty.length > 0){
          this.metadata.addAdditionalOrganisationsToParty.forEach(v => {
            const orgId = this.detailContext.resolveDataPath(v);
            if(orgId){
              planningService.addPartyToPlanning(orgId, null, value["dynamic_Properties"].planning, "ORGANISATION");
            }
          })
        }
        if(this.metadata.addAdditionalUsersToParty && Array.isArray(this.metadata.addAdditionalUsersToParty) && this.metadata.addAdditionalUsersToParty.length > 0){
          this.metadata.addAdditionalUsersToParty.forEach(v => {
            const userId = this.detailContext.resolveDataPath(v);
            if(userId){
              planningService.addPartyToPlanning(userId, null, value["dynamic_Properties"].planning, "USER");
            }
          })
        }
        this.createNewcontrol?.setReferenceId(value.id);
        return !!value.id;
      });
  }
  async updateNewLot(form: DynamicForm, formData: any): Promise<boolean> {
    if (!this.updatingLotId) return Promise.reject("no lot id");
    return dossierServiceV2
      .updateLot(
        form,
        this.detailContext.data.dossierId,
        this.updatingLotId,
        formData,
        this.updatingLotInitData?.status
      )
      .then((value) => {
        this.modifyLotcontrol?.setReferenceId(value.id);
        return !!value.id;
      });
  }

  async scrapLot(
    form: DynamicForm,
    data: any,
    referenceId: string
  ): Promise<boolean> {
    if (!this.updatingLotId) return Promise.reject("no lot id");
    return dossierServiceV2
      .deleteLot(
        form,
        this.detailContext.data.dossierId,
        this.updatingLotId,
        this.updatingLotInitData
      )
      .then((value) => !!value.id);
  }

  async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    this.detailContext.root?.getData();
    return Promise.resolve(true);
  }

  modifyLotcontrol: FormDialogControl | null = null;
  recieveModifyLotDialogControl(control: FormDialogControl) {
    this.modifyLotcontrol = control;
    this.modifyLotcontrol.submitChanges = this.updateNewLot;
    this.modifyLotcontrol.afterSubmitChanges = this.refreshOverview;
    this.modifyLotcontrol.destroyItem = this.scrapLot;
    this.modifyLotcontrol.afterDestroyItem = this.refreshOverview;
  }

  createNewcontrol: FormDialogControl | null = null;

  recieveCreateNewLotDialogControl(control: FormDialogControl) {
    this.createNewcontrol = control;
    this.createNewcontrol.submitChanges = this.createNewLot;
    this.createNewcontrol.afterSubmitChanges = this.refreshOverview;
  }
}
