import {
  Overview,
  OverviewDefinition,
  OverviewRequest,
} from "@/app/models/overview/overview.model";
import axios from "@/plugins/axios";
import store from "@/store";

class OverviewService {

  private definitionCache: {[key: string]: any} = {}

  getOverviewDefinition(overviewDefinitionId: string): Promise<OverviewDefinition> {
    if(this.definitionCache[overviewDefinitionId]) return Promise.resolve(this.definitionCache[overviewDefinitionId]);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/overviews/definition/${overviewDefinitionId}`;
    return axios.get<OverviewDefinition>(url).then((response) => {
      if(response.status === 200){
        this.definitionCache[overviewDefinitionId] = response?.data;
        return response?.data;
      }else{
        return Promise.reject('Could not load definition');
      }
    });
  }

  getOverviewData(
    overviewDefinition: OverviewDefinition,
    overviewDefinitionId: string,
    request: OverviewRequest
  ): Promise<Overview> {
    const sort = request.sort.sortBy
      ? request.sort.sortBy + "," + (request.sort.sortDesc ? "desc" : "asc")
      : "";
    let url = `${store.state.appConfig.apiBaseUrl}${request.path}?page=${request.page.number}&pageSize=${request.page.size}&sort=${sort}`;
    if (request.path.includes("?")) {
      url = `${store.state.appConfig.apiBaseUrl}${request.path}&page=${request.page.number}&pageSize=${request.page.size}&sort=${sort}`;
    }

    if (request.q && request.q != "" && request.q != " ") {
      url += `&q=${request.q}`;
    }
    if (request.filters && Object.keys(request.filters).length > 0) {
      const mappedFilters = Object.entries(request.filters).filter(
        (f) => f[1] && f[1] != "" && f[1] != " "
      );
      if (mappedFilters.length > 0) {
        url += `&filter=`;
        mappedFilters.forEach((value) => {
          url += `${value[0]}:'${value[1]}',`;
        });
        url = url.substring(0, url.length - 1);
      }
    }
    return axios.get<Overview>(url).then((response) => {
      return response.data;
    });
  }

  getOverviewUrl(request: OverviewRequest): string {
    const sort = request.sort.sortBy
        ? request.sort.sortBy + "," + (request.sort.sortDesc ? "desc" : "asc")
        : "";
    let url = `${store.state.appConfig.apiBaseUrl}${request.path}?page=${request.page.number}&pageSize=${request.page.size}&sort=${sort}`;
    if (request.path.includes("?")) {
      url = `${store.state.appConfig.apiBaseUrl}${request.path}&page=${request.page.number}&pageSize=${request.page.size}&sort=${sort}`;
    }

    if (request.q && request.q != "" && request.q != " ") {
      url += `&q=${request.q}`;
    }
    if (request.filters && Object.keys(request.filters).length > 0) {
      const mappedFilters = Object.entries(request.filters).filter(
          (f) => f[1] && f[1] != "" && f[1] != " "
      );
      if (mappedFilters.length > 0) {
        url += `&filter=`;
        mappedFilters.forEach((value) => {
          url += `${value[0]}:'${value[1]}',`;
        });
        url = url.substring(0, url.length - 1);
      }
    }
    return url;
  }

  getOverviewDataItem(
    value: any,
    overviewPath: any,
    param: string
  ): Promise<Overview> {
    let url = `${store.state.appConfig.apiBaseUrl}${overviewPath}?page=0&pageSize=1&sort=`;
    if (overviewPath.includes("?")) {
      url = `${store.state.appConfig.apiBaseUrl}${overviewPath}&page=0&pageSize=1&sort=`;
    }
    url += `&filter=${param}:'${value}'`;
    return axios.get<Overview>(url).then((response) => {
      return response.data;
    });
  }
}

export const overviewService = new OverviewService();
