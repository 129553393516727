






import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import { authenticationService } from "@/services/authentication.service";
import LottieImage from "@/app/components/lottie-image.vue";

@Component({
  components: {},
})
export default class CallbackView extends ActivityDependendViewBase {

  async onPageReady(): Promise<any> {
    return;
  }

}
