






import { Component, Vue } from "vue-property-decorator";
import { notificationService } from "@/services/notification.service";
import NotificationDto from "@/contracts/notification/NotificationDto";
import Severity from "@/contracts/notification/Severity";
import {activityServicev2} from "@/app/services/activity.service";
import store from "@/store";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import router from "@/router";
import ActivityModel from "@/app/models/activity/activity.model";
import IamRole from "@/contracts/iam/IamRole";

@Component({
  components: {},
})
export default class ActivityWatcher extends Vue {

  timedNotificationEventId = 0;
  private activityMaintenceWindowTimer: any;

  mounted(): void {
    this.setUpTimedActivityFetcher();
    if(!this.activityMaintenceWindowTimer){
      this.activityMaintenceWindowTimer = setInterval(this.checkMaintenanceWindow, 60000);
    }
  }

  beforeDestroy(): void {
    this.clearTimedActivityFetcher();
    if(this.activityMaintenceWindowTimer){
      clearInterval(this.activityMaintenceWindowTimer)
      this.activityMaintenceWindowTimer = undefined;
    }
  }

  async checkMaintenanceWindow(){
    const activityDefinition = store.state.activity?.currentActivityDefinition;
    if(activityDefinition && activityDefinition.maintenance){
      const now = new Date().getTime();
      let isMaintenanceActive = activityDefinition.maintenance.startTime && activityDefinition.maintenance.startTime.getTime() < now;
      if(activityDefinition.maintenance.endTime && activityDefinition.maintenance.endTime.getTime() < now ) isMaintenanceActive = false;

      if(isMaintenanceActive){
        const userRoles: IamRole[] = store.state.user?.roles;
        const canBypassMaintenance = (userRoles && Array.isArray(userRoles) && userRoles.filter(role => 'a94a46b6-8b08-f011-bae3-6045bd973240' === role.id).length > 0)
        if(canBypassMaintenance){
          notificationService.setStaticNotification({
            title: "general.bypass_maintenance_screen.title",
            message: "general.bypass_maintenance_screen.message",
            type: "Info"
          })
        }else{
          if(!this.$route.fullPath.startsWith('/maintenance')){
            await router.push({path: '/maintenance', replace: true});
            return;
          }
        }
      }
    }
  }

  async refreshActivity(): Promise<void> {
    const userId = store.state.user?.contactId;
    if(!userId){
      return;
    }

    let eligibleActivitiesForUser: ActivityModel[] = [];
    try {
      eligibleActivitiesForUser = await activityServicev2.getEligibleActivitiesForUser(userId);
      if(!eligibleActivitiesForUser){
        return ;
      }
      store.commit("setEligibleActivities", eligibleActivitiesForUser);
    }catch (e) {
      return ;
    }

    const currentActivityId = store.state.activity.currentActivity?.id;
    if(!currentActivityId){
      return ;
    }

    //revoke access if no longer found
   if(eligibleActivitiesForUser.filter(value => value.id === currentActivityId).length <= 0){
     // activity no longer found in list. navigate away
     this.$toast.error(this.$t("general.activity.noaccessanymore"));
     setTimeout(async () => {
       await router.replace('/');
       window.location.reload();
     },100);
     return;
   }

    const currentActivity = eligibleActivitiesForUser.filter(value => value.id === currentActivityId)[0]
    const currentActivityDefinition = await activityServicev2.getActivityDefinition(currentActivityId, true);
    const storedActivityDefinition = store.state.activity.currentActivityDefinition;

    if(!currentActivityDefinition || !storedActivityDefinition){
      return;
    }

    const s_currentActivityDefinition = JSON.stringify(currentActivityDefinition);
    const s_storedActivityDefinition = JSON.stringify(storedActivityDefinition);
    if(s_currentActivityDefinition === s_storedActivityDefinition){
      return;
    }

    await this.activityChanged(currentActivity, currentActivityDefinition, storedActivityDefinition);
  }

  setUpTimedActivityFetcher(): void {
    this.timedNotificationEventId = setInterval(
        async () => {
          try {
            await this.refreshActivity();
          }catch (e) {
            Vue.$toast.error("Something went wrong while refreshing the current activity.");
          }
        },
        300000
    );
  }

  clearTimedActivityFetcher(): void {
    if(this.timedNotificationEventId){
      clearInterval(this.timedNotificationEventId);
      this.timedNotificationEventId = 0;
    }
  }

  private async activityChanged(activity: ActivityModel, newActivityModel: ActivityDefinitionModel, prevActivityModel: ActivityDefinitionModel) {
    notificationService.setStaticNotification({
      title: "general.updated_activity.title",
      message: "general.updated_activity.message",
      type: "Info"
    })
    console.warn('activity changed', JSON.stringify(newActivityModel), JSON.stringify(prevActivityModel));
    this.$toast.info(this.$t("general.updated_activity_incomming_in_60"));

    setTimeout(() => {
      this.$toast.info(this.$t("general.updated_activity_incomming_5"));
    }, 60000);
    setTimeout(() => {
      notificationService.setStaticNotification(null)
      setTimeout(async ()=>{
        window.location.reload();
      },100);
    }, 65000);
    //await store.dispatch("setCurrentActivity", activity);
  }
}
