

















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { RenderType } from "@/app/Types";
import { ComponentOptions } from "vue";
import { ExternalContext } from "@/app/contexts/externalContext";
import Loader from "@/components/common/Loader.vue";

@Component({
  components: { Loader },
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class FormWrapper extends Vue {
  protected formLoading = true;
  form: DynamicForm | null = null;

  @Prop({ default: false })
  processing!: boolean;

  @Prop({ default: 48 })
  public loaderSize!: number;

  @Prop({ default: () => {return null;} })
  formDefinition!: any;
  formDefinitionDebounce = new Subject<any>();
  formDefinitionDebounceSubscription: Subscription | undefined = undefined;
  @Watch("formDefinition", { immediate: true, deep: true })
  setDefinition(newJson: any, oldJson: any) {
    if(newJson){
      this.formDefinitionDebounce.next([this, newJson]);
    }
  }

  @Prop({
    default: () => {
      return new ExternalContext();
    },
  })
  protected externalContext!: ExternalContext;

  @Watch("externalContext", { immediate: true, deep: true })
  setExternalContext(newExternalContext: any, oldExternalContext: any) {
    if (this.form) {
      this.form.setExternalContext(newExternalContext);
    }
  }

  constructor() {
    super({
      computed: {
        ComponentTypeDictionaryService() {
          return ComponentTypeDictionaryService;
        },
      },
    });
    this.formDefinitionDebounceSubscription = this.formDefinitionDebounce
      .pipe(debounceTime(100))
      .subscribe((args) => {
        const wrapper: FormWrapper = args[0];
        const newJson = args[1];

        if (!newJson) {
          if (wrapper.form) {
            wrapper.form.destroy();
            wrapper.form = null;
          }
          setTimeout(() => (wrapper.formLoading = false), 1500);
          return;
        }
        if (wrapper.form) {
          wrapper.form.destroy();
        }
        wrapper.form = DynamicForm.BUILD_FROM_JSON_DEFINITION(
          newJson,
          wrapper,
          this.externalContext
        );
        wrapper.$emit("dynamicForm", wrapper.form);
        setTimeout(() => (wrapper.formLoading = false), 1500);
      });
  }

  mounted(): void {
    return;
  }

  beforeDestroy(): void {
    this.form?.destroy();
    if (this.formDefinitionDebounceSubscription) {
      this.formDefinitionDebounceSubscription.unsubscribe();
      this.formDefinitionDebounceSubscription = undefined;
    }
  }
  formDataChanged(formData: any) {
    this.$emit("formDataChanged", formData);
  }

  public static async SAVE_FORM_ASYNC(form: DynamicForm, onStartSaving: () => void, onCompleteSaving: () => void, saveFormEntity: (formData, form) => Promise<{ id: string; type: string }>): Promise<boolean>{
    form.getData();

    const errors = await form.getErrors();
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return false;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return false;
    }

    onStartSaving.apply(this, []);

    try{
      const beforeSaveSuccess = await form.beforeSave();
      if (!beforeSaveSuccess) {
        this.$toast.error("Couldn't complete prerequisites.");
        onCompleteSaving.apply(this, []);
        return false;
      }
    }catch (e) {
      this.$toast.error("Couldn't complete prerequisites.");
      onCompleteSaving.apply(this, []);
      return false;
    }

    // fetch data again, as beforesave can alter the contents
    let savedEntity
    const dataBeforeSave = form.getData();
    try{
      savedEntity = await saveFormEntity.apply(this, [dataBeforeSave, form]);
    }catch (e) {
      Vue.$toast.error("Couldn't complete request.");
      onCompleteSaving.apply(this, []);
      return false;
    }

    try{
      const afterSaveSuccess = await form.afterSave({
        type: savedEntity?.type,
        referenceId: savedEntity?.id,
      });
      if (!afterSaveSuccess) {
        this.$toast.error("Couldn't complete post actions.");
        onCompleteSaving.apply(this, []);
        return false;
      }
    }catch (e) {
      this.$toast.error("Couldn't complete post actions.");
      onCompleteSaving.apply(this, []);
      return false;
    }

    onCompleteSaving.apply(this, []);
    return true;
  }
}
