import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import store from "@/store";
import axios from "@/plugins/axios";
import {DynamicDetailDefinition} from "@/app/dynamic-components/details/dynamic-detail.model";
import {FormDialogModel} from "@/app/components/dialog/form-dialog.model";
import {ExternalContext} from "@/app/contexts/externalContext";

class FormService {

    private definitionCache: {[key: string]: any} = {}
    async getFormDefinition(formDefinition: string): Promise<DynamicDetailDefinition> {
        if(this.definitionCache[formDefinition]) return Promise.resolve(this.definitionCache[formDefinition]);

        const url = `${store.state.appConfig.apiBaseUrl}api/v2/forms/definition/${formDefinition}`;
        return axios
            .get<DynamicDetailDefinition>(url, {})
            .then((response) => {
                if(response.status === 200){
                    this.definitionCache[formDefinition] = response?.data;
                    return response?.data;
                }else{
                    return Promise.reject('Could not load definition');
                }
            });
    }

    async getDialogDefinition(formDefinition: string): Promise<FormDialogModel | null> {
        if(this.definitionCache[formDefinition]) return Promise.resolve(this.definitionCache[formDefinition]);

        const url = `${store.state.appConfig.apiBaseUrl}api/v2/forms/definition/${formDefinition}`;
        return axios
            .get<FormDialogModel>(url, {})
            .then((response) => {
                if(response.status === 200){
                    const val = {
                        destroyLabel: response.data.destroyLabel || null,
                        externalContext: new ExternalContext(),
                        form: response.data.form,
                        referenceId: response.data.referenceId,
                        referenceType: response.data.referenceType,
                        submitLabel: response.data.submitLabel  || null,
                        title: response.data.title,
                        multiplicityReference: response.data.multiplicityReference
                    };
                    this.definitionCache[formDefinition] = val;
                    return val;
                }else{
                    return Promise.reject('Could not load definition');
                }
            });
    }
}
export const formService = new FormService();

