
























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Step from "@/contracts/common/Step";
import { DynamicFormEntryModel } from "@/app/dynamic-components/dynamic-components.model";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import {
  FormDialogModel,
  FormDialogControl,
} from "@/app/components/dialog/form-dialog.model";
import Loader from "@/components/common/Loader.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import {formService} from "@/app/services/form.service";
import {ExternalContext} from "@/app/contexts/externalContext";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";

@Component({
  components: { Loader, FormWrapper },
})
export default class DialogComponent extends Vue {
  processing = false;
  dialogOpen = false;
  itemId: string | undefined = undefined;

  private control!: FormDialogControl;

  @Prop({ default: "800" })
  dialogWidth!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  formDialogModel!: FormDialogModel | null;

  @Prop({
    default: () => {
      return null;
    },
  })
  formDialogDefinitionId!: string;

  @Prop({
    default: () => {
      return new ExternalContext();
    },
  })
  externalContext!: ExternalContext;

  @Watch("externalContext", {immediate: true, deep: false})
  onexternalContextUpdate(newExternalContext: ExternalContext | null): void {
    if(!newExternalContext) return;
    this.setExternalContext(newExternalContext);
  }


  mounted() {
    this.control = new FormDialogControl(this);
    this.$emit("formDialogControl", this.control);
  }

  public formData: any | null = null;
  onFormDataChanged(formData: any) {
    this.formData = formData;
  }

  form: DynamicForm | null = null;
  recieveForm(form: DynamicForm) {
    this.form = form;
  }

  @Watch("formDialogDefinitionId", {immediate: true, deep: false})
  onFormDialogDefinitionIdUpdate(newFormDialogDefinitionId: string | null, oldFormDialogDefinitionId: string | null): void {
    if(!newFormDialogDefinitionId) return;
    if(newFormDialogDefinitionId === oldFormDialogDefinitionId) return;

    formService.getDialogDefinition(newFormDialogDefinitionId).then(formDefinition => {
      if(!formDefinition) return;
      this.formDialogModel = formDefinition;
    }).catch(reason => {
      console.error("Unable to construct dialog", reason)
      Vue.$toast.error("Unable to construct dialog, some functions might not work properly.");
    })
  }

  closeDialog() {
    this.dialogOpen = false;
    if (this.form) {
      this.form.reset();
    }
  }

  async destroyDialog() {
    if (!this.itemId || !this.form) {
      this.$toast.error("Can't destroy what doesn't exist");
      return;
    }

    const submit: Promise<boolean> = this.control.destroyItem.apply(this, [
      this.form,
      this.formData,
      this.itemId,
    ]);
    submit
      .then(async (success) => {
        if (success) {
          if (!this.itemId || !this.form) return;

          this.processing = false;
          Vue.$toast.success("Destroyed item");

          await this.control.afterDestroyItem.apply(this, [
            this.form,
            this.formData,
            this.itemId,
          ]);
          this.closeDialog();
        } else {
          Vue.$toast.error("Something went wrong.");
          this.processing = false;
        }
      })
      .catch((reason) => {
        Vue.$toast.error("Something went wrong.");
        this.processing = false;
      });

    if(this.control.destroyDone){
      await this.control.destroyDone();
    }
  }

  async confirmDialog() {
    if (!this.form) return;

    const errors = await this.form.getErrors();
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      console.warn('validation errors', errors);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      console.warn('validation errors', errors);
      return;
    }

    const toCreateForRefences: string[] = []
    if(this.formDialogModel?.multiplicityReference){
        let multiplicityReferences = this.form.resolveDataPath(this.formDialogModel.multiplicityReference);
        if(Array.isArray(multiplicityReferences)){
          multiplicityReferences.forEach(a => toCreateForRefences.push(a));
        }else if (typeof multiplicityReferences === 'string'){
          toCreateForRefences.push(multiplicityReferences)
        }else{
          toCreateForRefences.push("");
        }
    }else{
      toCreateForRefences.push("");
    }


    this.processing = true;
    if(!this.form) return;
    const originalFormData = this.form.formData;

    for (let i = 0; i < toCreateForRefences.length; i++){
      let toCreateForRefence = toCreateForRefences[i];

      if(!this.formDialogModel) return;
      if(!this.form) return;
      if(i > 0){
        this.form.patchForm(originalFormData);
      }


      if(this.control.transformBeforeSave != null){
        let transformSuccess = false;
        try{
          transformSuccess = await this.control.transformBeforeSave.apply(this, [this.form,this.formData,this.itemId, toCreateForRefence])
        }catch (reason) {
          Vue.$toast.error("Something went wrong.");
          console.error(reason);
          this.processing = false;
          return;
        }
        if (!transformSuccess) {
          this.$toast.error("Failed some prerequisites for saving.");
          this.processing = false;
          return;
        }
      }

      if(this.form.beforeSave != null){
        let beforeSaveSuccess = false;
        try{
          console.warn('beforesave');
          beforeSaveSuccess = await this.form.beforeSave();
        }catch (reason) {
          Vue.$toast.error("Something went wrong.");
          console.error(reason);
          this.processing = false;
          return;
        }
        if (!beforeSaveSuccess) {
          this.$toast.error("Failed some prerequisites for saving.");
          this.processing = false;
          return;
        }
      }

      if(this.control.submitChanges != null){
        let submitSuccess = false;
        try{
          submitSuccess = await this.control.submitChanges.apply(this, [this.form,this.formData,this.itemId]);
        }catch (reason) {
          Vue.$toast.error("Something went wrong.");
          console.error(reason);
          this.processing = false;
          return;
        }
        if(!submitSuccess){
          Vue.$toast.error("Something went wrong.");
          this.processing = false;
          return;
        }
      }

      //todo: not great, would be better injected into the result function if possible. Relies on submitChanges to set it in the model
      const submit = {
        type: this.formDialogModel.referenceType,
        referenceId: this.formDialogModel.referenceId,
      };
      this.formDialogModel.externalContext.setData("submit", submit);
      this.formDialogModel.externalContext.setData("id",this.formDialogModel.referenceId);
      this.formDialogModel.externalContext.setData("type",this.formDialogModel.referenceType);

      if(this.form.afterSave != null){
        let afterSaveSuccess = false;
        try{
          afterSaveSuccess = await this.form.afterSave(submit);
        }catch (reason) {
          Vue.$toast.error("Something went wrong.");
          console.error(reason);
          this.processing = false;
          return;
        }
        if (!afterSaveSuccess) {
          this.$toast.error("Some post actions failed!");
          this.processing = false;
          return;
        }
      }

      if(this.control.afterSubmitChanges != null){
        let afterSubmitChangesSuccess = false;
        try{
          afterSubmitChangesSuccess = await this.control.afterSubmitChanges.apply(this, [this.form,this.formData,this.itemId,]);
        }catch (reason) {
          Vue.$toast.error("Something went wrong.");
          console.error(reason);
          this.processing = false;
          return;
        }
        if(!afterSubmitChangesSuccess){
          this.$toast.error("Some post actions failed!");
          this.processing = false;
          return;
        }
      }

    }

    Vue.$toast.success("Successfully saved.");
    this.processing = false;
    if(this.control.submitDone){
      await this.control.submitDone();
    }
    this.closeDialog();
  }

  showDialog(referenceId?: string, initFormData?: any) {
    this.dialogOpen = true;
    this.itemId = referenceId;
    if (initFormData) {
      this.form?.patchForm(initFormData);
      setTimeout(() => {
        this.form?.resetValidation();
        this.form?.onShow();
      }, 300);
    } else {
      this.form?.reset();
      this.form?.onShow();
    }
  }

  private setExternalContext(context: ExternalContext) {
    if(!context || !this.formDialogModel) return;
    this.formDialogModel.externalContext = context;
    if(!context.data['activitydefinition']) {
      const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
      context.setData("activitydefinition", activityDefinition);
    }
  }
}
