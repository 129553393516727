








































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import {Component, Vue} from "vue-property-decorator";
import Loader from "@/components/common/Loader.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import {ruleEngine} from "@/app/services/rule.engine";
import {EventDictionary} from "@/app/events/event.dictionary";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

export type DetailValidationRequestMetaData = {
  buttonLabel: string;
  dialogWidth: string;
  title: string;
  confirmRequestLabel: string
  proccessingLabel: string
  validationEntries: {[key: string]: string}
  completionEventId: string;
  requestCompletedLabel: string;
  requestCompletedDesc: string;

  fireEvent: string //event to fire when requesting the action after validation
  //other event parameters are defined here. check event for specifics
};

@Component({
  components: {FormWrapper, Loader}
})
export default class DetailComponentValidationRequest extends DetailComponentBase<DetailValidationRequestMetaData> {
  processing = false;
  dialogOpen = false;
  invalidRequest = true;
  waitForCompleteValidation = false;
  validationEntries: Array<{
    title: string,
    check: boolean,
    processing: boolean
    rule: string
  }> = [];

  postEntityChanged(newValue: DynamicDetailEntry) {
    return;
  }

  openValidationRequester(){
    this.dialogOpen = true;
    this.requestValidation();
  }

  closeValidationRequester(){
    this.dialogOpen = false;
  }

  async requestValidation(){
    this.invalidRequest = true;
    this.processing = true;
    this.validationEntries = [];
    try{
      if(this.metadata.validationEntries && typeof this.metadata.validationEntries === 'object'){
        this.validationEntries = [];
        Object.entries(this.metadata.validationEntries).forEach(entry => {
          this.validationEntries.push({
            title: entry[0],
            check: false,
            rule: entry[1],
            processing: true
          })
        });

        const results = await Promise.all(this.validationEntries.map(value => this.processValidationEntry(value)));
        this.invalidRequest = this.validationEntries.filter(value => !value.check).length > 0;
        this.processing = false;
      }else{
        this.processing = false;
      }
    }catch (e){
      this.processing = false;
    }
  }
  async completeValidation(){
    this.waitForCompleteValidation = true;
    try{
      await EventDictionary[this.metadata.completionEventId].getCallBack(this.detailContext, this.metadata, this.entry?.metadata);
      this.waitForCompleteValidation = false;
      //Vue.$toast.success(this.$t(this.metadata.requestCompletedLabel|| 'Success') as string);
      if(this.detailContext?.root instanceof DossierDetailComponent){
        this.detailContext?.root.getData();
      }
      this.closeValidationRequester();
    }catch (e){
      this.waitForCompleteValidation = false;
    }

  }

  private async processValidationEntry(entry: { title: string; check: boolean; processing: boolean; rule: string }): Promise<{ title: string; check: boolean; processing: boolean; rule: string }> {
    const result = await ruleEngine.resolveRuleAsync(this.detailContext, entry.rule, this);
    entry.check = result;
    entry.processing = false;

    /*await new Promise(resolve => setTimeout(() => {
      entry.check = result;
      entry.processing = false;
      resolve(true);
    }, 4000));*/
    return entry;
  }
}
