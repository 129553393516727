






















import { Component, Vue } from "vue-property-decorator";
import { notificationService } from "@/services/notification.service";
import NotificationDto from "@/contracts/notification/NotificationDto";
import Severity from "@/contracts/notification/Severity";
import { Subject, Subscription } from "rxjs";

@Component({
  components: {},
})
export default class NotificationBanner extends Vue {
  severity: Severity[] = [
    { name: "Error", color: "deep-orange accent-4", icon: "mdi-alert-circle" },
    { name: "Warning", color: "orange darken-3", icon: "mdi-alert" },
    { name: "Info", color: "blue darken-3", icon: "mdi-information" },
    { name: "Success", color: "green darken-3", icon: "mdi-check-circle" },
  ];
  relevantNotification: NotificationDto = new NotificationDto();
  relevantSeverity: Severity = new Severity();
  timedNotificationEventId = 0;
  staticNotifincationSubsciption: Subscription | null = null;
  previousNotification: NotificationDto | null = null;
  previousSeverity: Severity | null = null;

  get severityColor(): string | null {
    return this.relevantSeverity.color;
  }

  get severityIcon(): string | null {
    return this.relevantSeverity.icon;
  }

  mounted(): void {
    this.getRelevantNotification();
    this.setUpTimedGetNotification();
    this.staticNotifincationSubsciption =  notificationService.getStaticNotifications().subscribe(notification => {
      if(this.previousNotification && this.previousSeverity && !notification){
        this.relevantNotification = this.previousNotification;
        this.relevantSeverity = this.previousSeverity;
        this.previousNotification = null;
        this.previousSeverity = null;
        return;
      }

      if(notification && notification.title !== this.relevantNotification?.title){
        this.previousNotification = this.relevantNotification;
        this.previousSeverity = this.relevantSeverity;
        this.relevantNotification = {
          title: notification.title,
          message: notification.message,
          severityName: notification.type,
          severity: this.severity.indexOf(this.severity.filter(value => value.name === notification.type)[0]),
          id: null,
          startDate: new Date(),
          endDate: new Date()
        }
        this.relevantSeverity = this.severity.filter(value => value.name === notification.type)[0];
      }

    });
  }

  beforeDestroy(): void {
    this.clearTimedGetNotification();
    if (this.staticNotifincationSubsciption) this.staticNotifincationSubsciption.unsubscribe();
    this.staticNotifincationSubsciption = null;
  }

  getRelevantNotification(): void {
    notificationService.getRelevantNotification().then((data) => {
      if(this.previousNotification != null) return;

      this.relevantNotification = JSON.parse(JSON.stringify(data));
      let foundSeverity = this.severity.find(
        (severityLevel) =>
          severityLevel.name === this.relevantNotification.severityName
      );
      this.relevantSeverity = foundSeverity ? foundSeverity : new Severity();
    });
  }

  setUpTimedGetNotification(): void {
    this.timedNotificationEventId = setInterval(
      () => this.getRelevantNotification(),
      120000
    );
  }

  clearTimedGetNotification(): void {
    clearInterval(this.timedNotificationEventId);
  }
}
