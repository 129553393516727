import { Component } from "vue-property-decorator";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import BaseDynamicCompositeComponent from "@/app/dynamic-components/forms/form-components/form-component-composite.base";
import { RenderItem } from "@/app/Types";

@Component({})
export default class FormComponentExternalEntityBase<
  T,
  S
> extends BaseDynamicCompositeComponent<T> {
  public externalEntityId: string | null = null;
  public externalEntity: S | null = null;
  public formData: any;
  public loading = false;
  public createdNew = false;

  protected async convertFormDataToValue(formData: any): Promise<any> {
    this.formData = formData;
    return this.externalEntityId;
  }

  protected async convertValueToFormData(value: any): Promise<any> {
    if (this.useAlternativeFetch()) {
      /*this.loading = true;
      this.externalEntity = await this.fetchExternalEntity(value);
      this.externalEntityId = this.externalEntity ? this.externalEntity['id'] : null;
      this.loading = false;*/
      return this.externalEntity;
    }

    if (!value) {
      this.externalEntity = null;
      this.externalEntityId = null;
      return null;
    }

    if (value != this.externalEntityId) {
      this.externalEntityId = value;
      this.loading = true;
      this.externalEntity = await this.fetchExternalEntity(value);
      this.loading = false;
      return this.externalEntity;
    }
  }

  async beforeSave(): Promise<boolean> {
    if (this.definition && !this.formData) return true;

    if (this.externalEntityId) {
      //update existing
      await this.updateExternalEntity(
        this.externalEntityId,
        this.externalEntity,
        this.formData
      );
    } else {
      //create new
      this.externalEntityId = await this.createExternalEntity(this.formData);
      this.entry.setValue(this.externalEntityId, {
        immediate: true,
        emitEvents: true,
      });
      this.createdNew = true;
    }
    return true;
  }

  async afterSave(sumbitData: any): Promise<boolean> {
    if (this.createdNew) {
      this.createdNew = false;
      await this.createExternalEntityAfterSave(sumbitData);
    }
    return true;
  }

  //don't forget to override this one

  protected async fetchExternalEntity(value: any): Promise<S | null> {
    return null;
  }

  //don't forget to override this one
  protected createDefinition(): RenderItem | null {
    return null;
  }

  protected async createExternalEntity(formData: any): Promise<string> {
    return "";
  }

  protected async updateExternalEntity(
    externalEntityId: string,
    externalEntity: S | null,
    formData: any
  ) {
    return;
  }

  protected async createExternalEntityAfterSave(sumbitData: any) {
    return;
  }

  protected useAlternativeFetch(): boolean {
    return false;
  }
}
