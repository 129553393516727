import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import router from "@/router";

export default class OverviewRowClickNavigationEvent extends EventBase {
  getCallBack(context: ContextAware, payload?: any): any {
    router.push({
      name: payload.metadata.route,
      params: {
        id: payload.item.item["id"],
      },
    });
  }
}
