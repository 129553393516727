import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

export default class ShowDialogEvent extends EventBase {
    async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
      if(!(context instanceof DetailContext)) return Promise.reject('Context is not a detailcontext');
      const detailsContext: DetailContext = context;
      if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent) {
          detailsContext.root.setModal(payload.dialogDefinitionId, payload.referenceId, detailsContext, payload.data, (form, formData, referenceId) => {
              return Promise.resolve(true);
          }, (form, formData, referenceId) => {
              return Promise.resolve(true);
          }, (form, formData, referenceId, multiplicityReferenceId) => {
              return Promise.resolve(true);
          });
      }
  }
}
