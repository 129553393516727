export interface DocumentMetadata {
  id: string;
  name: string;
  description: string;
  category: string;
  type: string[];
  createdOn: string;
  lastModifiedOn: string;
  activity: string;
  activityId: string;
  uploadedBy: any;
  uploadedByEmail: string;
  uploadedByMembershipId: string;
  organization: string;
  organizationId: string;
  shared: boolean;
}

export class DocumentFile {
  public uploaded = false;
  public fetched = false;

  public fileToupload: File | undefined;

  public documentId: string | undefined;

  public name: string | undefined;

  public description: string | undefined;

  public category: string | undefined;

  public type: string[] | undefined;

  public activityId: string | undefined;

  ToFormData(): any {
    const formData = new FormData();
    if (this.fileToupload) {
      formData.append("file", this.fileToupload);
    }
    if (this.fileToupload?.name && this.fileToupload?.name) {
      formData.append("name", this.fileToupload?.name);
    }
    return formData;
  }

  setFetchedMetadata(documentMetadata: DocumentMetadata) {
    this.fetched = true;
    this.name = documentMetadata.name;
    this.category = documentMetadata.category;
    this.activityId = documentMetadata.activityId;
    this.description = documentMetadata.description;
    this.type = documentMetadata.type;
  }

  setNewFile(file: File, name: string) {
    this.fileToupload = file;
    this.name = name;
  }

  setUploadedFile(documentId: any) {
    this.documentId = documentId;
    this.uploaded = true;
  }
}
