import {
  FormValidator,
  FormValidatorBase,
} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import {DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";

export default class MinLengthFormValidator extends FormValidatorBase {
  constructor() {
    super("minLength", "validations.minLength");
  }

  isValid(formData: any, entry: DynamicFormEntry): boolean {
    if (formData === undefined || formData === null) return false;
    if(typeof formData !== 'string') return false;
    return formData.length >= this.params[0];
  }

  constructImpl(params: any[]): FormValidator {
    const validator = new MinLengthFormValidator();
    params[0]  = params.length <= 0 ? 0 : Number.parseInt(params[0]);
    validator.params = params;
    return validator;
  }
}
