import * as msal from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { AccountInfo, AuthenticationResult } from "@azure/msal-common";
import IamConfig from "@/contracts/iam/IamConfig";
import { iamService } from "@/services/iam.service";
import store from "@/store";
import router from "@/router";

class MsalService {
  private msalInstance!: PublicClientApplication;
  private iamConfig!: IamConfig;
  private scopes: string[] = [];

  isMsalInstantiated(): boolean {
    return this.msalInstance !== undefined;
  }

  async init() {
    try{
      const iamConfigResponse = await iamService.getConfig();
      if(iamConfigResponse.status !== 200){
        await router.push({path: '/maintenance',query: {'api-failed': 'true'}, replace: true});
        return;
      }
      this.iamConfig = iamConfigResponse?.data;
    }catch (e) {
      await router.push({path: '/maintenance',query: {'api-failed': 'true'}, replace: true});
      return;
    }

    const msalConfig = {
      auth: {
        clientId: store.state.appConfig.clientId,
        redirectUri: `${
          window.location.protocol + "//" + window.location.host
        }/callback`,
        postLogoutRedirectUri: `${
          window.location.protocol + "//" + window.location.host
        }/callback`,
        authority: this.iamConfig.azureB2C.loginAuthority,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [
          this.iamConfig.azureB2C.loginAuthority,
          this.iamConfig.azureB2C.passwordResetAuthority,
        ],
      },
      cache: {
        cacheLocation: "localStorage",
      },
    };

    this.msalInstance = new msal.PublicClientApplication(msalConfig);

    this.scopes = [
      `https://${store.state.appConfig.tenantName}.onmicrosoft.com/customer-portal-api/Portal.Access`,
    ];
  }

  setActiveAccount(account: AccountInfo | null): void {
    this.msalInstance.setActiveAccount(account);
  }

  acquireTokenSilent(): Promise<AuthenticationResult> {
    return this.msalInstance.acquireTokenSilent({
      scopes: [...this.scopes],
    });
  }

  acquireTokenRedirect(): Promise<void> {
    return this.msalInstance.acquireTokenRedirect({
      scopes: [...this.scopes],
    });
  }

  loginRedirect(): Promise<void> {
    return this.msalInstance.loginRedirect({
      scopes: [...this.scopes],
      authority: this.iamConfig.azureB2C.passwordResetAuthority,
    });
  }

  logoutRedirect(): Promise<void> {
    return this.msalInstance.logoutRedirect();
  }

  handleRedirect(): Promise<AuthenticationResult | null> {
    return this.msalInstance.handleRedirectPromise();
  }

  getAllAccounts(): AccountInfo[] {
    if(!this.msalInstance) return [];
    return this.msalInstance.getAllAccounts();
  }
}

export const msalService = new MsalService();
