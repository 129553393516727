

































import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import FormComponentTreeCheckbox, {TreeEntry} from "@/app/dynamic-components/forms/form-components/components/form-component-tree-checkbox.vue";


@Component({
  components: {}
})
export default class FormComponentTreeCheckboxEntry extends Vue {
  @Prop({ default: null })
  me!: FormComponentTreeCheckbox;

  @Prop({ default: () => { return null; } })
  item!: TreeEntry;

  @Prop({ default: 0 })
  depth!: number;

  @Watch("item", { immediate: true, deep: true })
  setItem(newTree:  TreeEntry, oldTree:  TreeEntry) {
    return;
  }

  showChilderen = true;

  toggleShowChilderen(){
    this.showChilderen = !this.showChilderen;
  }

  public anyChildIsChecked(item: TreeEntry){
    return item.children.filter(value => !!value.state).length > 0;
  }

  onCheckBoxValueChanged(entry: TreeEntry, filled: boolean): void {
    let newValue: any;
    if (!filled) {
      newValue = entry.templateValue === true ? false : null;
    }else{
      newValue = entry.templateValue;
    }

    if(entry.state !== newValue){
      entry.state = newValue;
      if(this.me.entry.metadata.autoSelectChildren){
        this.me.iterateChildrenDown(filled, entry);
      }
      if(this.me.entry.metadata.autoSelectParent){
        this.me.iterateChildrenUp(entry);
      }
      this.me.onCheckBoxValueChanged();
    }
  }
}
