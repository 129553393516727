import EventBase from "@/app/events/event-base";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";

export default class DeleteDossierEvent extends EventBase {
  constructor() {
    super();
  }

  async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
    console.log("test delete");
  }
}
