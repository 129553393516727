import axios from "@/plugins/axios";
import AppConfig from "@/contracts/generic/AppConfig";
import {AxiosResponse} from "axios";

class ConfigService {
  public getConfig(): Promise<AxiosResponse<AppConfig>> {
    return axios.get(`/config.json`);
  }
}

export const configService = new ConfigService();
