import {PlanningModel} from "@/app/models/planning/planning.model";
import store from "@/store";
import axios from "@/plugins/axios";
import {ContactAvailability, CreateSchedule, Schedule, UpdateSchedule} from "@/app/models/schedule/schedule.model";


class ScheduleService {

    filterAvailableContacts(blockType:string, contactIds: string[]): Promise<ContactAvailability[]> {
        const now = new Date();
        const before = new Date(now.getTime() - 10 * 1000);
        const after = new Date(now.getTime() + 10 * 1000);
        if(!contactIds || contactIds.length <= 0) return Promise.resolve([]);
        let url = `${store.state.appConfig.apiBaseUrl}api/v2/schedule/availability?ContactIds=${contactIds.join(',')}&StartTime=${before.toISOString()}&EndTime=${after.toISOString()}`;
        if(blockType){
            url += `&BlockType=${blockType}`;
        }

        return axios.get<ContactAvailability[]>(url).then((response) => {
            if(response.status !== 200) return [];
            return response.data.filter(available => available.available);
        });
    }

    getSchedule(id: any): Promise<Schedule | null> {
        if(!id) return Promise.resolve(null);
        const url = `${store.state.appConfig.apiBaseUrl}api/v2/schedule/${id}`;
        return axios.get<Schedule | null>(url).then((response) => {
            if(response.status !== 200) return null;
            return response.data;
        });
    }

    createSchedule(createSchedule: CreateSchedule): Promise<string> {
        if(!createSchedule) return Promise.reject("Nat a valid schedule");
        const url = `${store.state.appConfig.apiBaseUrl}api/v2/schedule`;
        return axios.post<string>(url,createSchedule).then((response) => {
            if(response.status !== 200) return Promise.reject("invalid status code " + response.status);
            return response.data;
        });
    }

    updateSchedule(id: string, updateSchedule: UpdateSchedule): Promise<void> {
        if(!updateSchedule) return Promise.reject("Nat a valid schedule");
        const url = `${store.state.appConfig.apiBaseUrl}api/v2/schedule/${id}`;
        return axios.put<any>(url,updateSchedule).then((response) => {
            if(response.status !== 200) return Promise.reject("invalid status code " + response.status);
            return response.data;
        });
    }
}

export const scheduleService = new ScheduleService();
