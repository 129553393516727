




import { Component, Prop, Vue } from "vue-property-decorator";
import lottie, {AnimationItem} from "lottie-web";

@Component({
  components: {  },
})
export default class LottieImage extends Vue {
  @Prop({ default: null })
  jsonAsset!: string;

  animation: AnimationItem | undefined = undefined

  mounted() {
      this.animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer as any,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: this.getJsonAssetAnimationData()
      })
  }


  private getJsonAssetAnimationData(): any {
    return require(`@/assets/${this.jsonAsset}`);
    //return require(`@/assets/lottie_maintenance.json`);
  }

  beforeDestroy() {
    if (this.animation) {
      this.animation.destroy(); // Clean up animation when component is destroyed
    }
  }


}
