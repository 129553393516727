







import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Subject, Subscription } from "rxjs";
import { EventDictionary } from "@/app/events/event.dictionary";
import {ruleEngine} from "@/app/services/rule.engine";

@Component({
  components: {},
})
export default class OverviewCellAction extends OverviewCellBase {
  canWork = false;
  working = false;
  eventbusSubcription: Subscription | undefined = undefined;

  icon = "";
  actionKey: string | null = null;

  async mounted(): Promise<void> {
    this.eventbusSubcription = this.eventbus?.subscribe((val) => {
      if (val.type === "CLEAR_ACTION") {
        this.working = false;
      }
    });
    await this.setActions();
  }

  protected PostResolveValue() {
    this.setActions();
  }

  protected async setActions(): Promise<void> {
    if(this.metadata?.multipleActions){
      const actionEntries = Object.entries(this.metadata.multipleActions);
      for (let i = 0; i < actionEntries.length; i++) {
        const actionKey: string = actionEntries[i][0];
        const action: any = actionEntries[i][1];
        if(action && (!action.rule || await ruleEngine.resolveRuleAsync(this.overviewContext,action.rule, this) )) {
          this.icon = action.icon || this.metadata.icon || "mdi-circle-small";
          this.actionKey = actionKey;
          return;
        }
      }
    }
    this.icon = this.metadata.icon || "mdi-circle-small";
    this.actionKey = null;
  }

  get color(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }

  fireEvent(): void {
    if (this.eventbus) {
      this.working = true;
      this.eventbus.next({
        type: "ACTION",
        metadata: {
          eventId: this.headerId,
          item: this.item,
          action: this.actionKey,
        },
        item: this.item,
      });
    }
  }

  beforeDestroy(): void {
    if (this.eventbusSubcription) this.eventbusSubcription.unsubscribe();
    this.eventbusSubcription = undefined;
  }
}
