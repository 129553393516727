interface UserContext {
    userId: string | null;
    organization: string | null;
    activity: string | null;
}

class AnalyticsService {
    userContext: UserContext;

    constructor() {
        this.userContext = {
            userId: null,
            organization: null,
            activity: null,
        };
    }

    setUserActivity(activity: string): void {
        //console.log("Setting activity to:", activity);
        this.userContext = {
            ...this.userContext,
            activity,
        };
    }

    setUserOrganization(organization: string): void {
        //console.log("Setting organization to:", organization);
        this.userContext = {
            ...this.userContext,
            organization,
        };
    }
    
    setUserId(userId: string): void {
        //console.log("Setting userId to:", userId);
        this.userContext = {
            ...this.userContext,
            userId,
        };
    }
    sendEvent(
        category: string,
        action: string,
        label: string,
        value: number,
        additionalParams?: Record<string, unknown>
    ): void {
        const eventParams: Record<string, unknown> = {
            event: "custom_event",
            event_name: action,
            event_category: category,
            event_action: action,
            event_label: label,
            value: value,
            user_id: this.userContext.userId,
            organization: this.userContext.organization,
            activity: this.userContext.activity,
            ...additionalParams, // Spread additional parameters if provided -> THESE MUST BE ADDED AS A EVENT PARAMETER IN THE GOOGLE ANALYTICS TAG IN THE DASHBOARD
        };

        //console.info("Sending event to dataLayer:", eventParams);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventParams);
    }

    trackPageView(url: string): void {
        const pageViewParams: Record<string, unknown> = {
            event: "page_view",
            page_path: url,
            user_id: this.userContext.userId,
            organization: this.userContext.organization,
            activity: this.userContext.activity,
        };

        //console.info("Sending page view to dataLayer:", pageViewParams);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(pageViewParams);
    }
}

export default new AnalyticsService();
