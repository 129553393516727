import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"accordion":"","multiple":"","value":_vm.defaultAllExpanded}},_vm._l((_vm.entry.children),function(child,i){return _c(VExpansionPanel,{key:i,attrs:{"id":child.id}},[_c(VExpansionPanelHeader,{directives:[{name:"show",rawName:"v-show",value:(child.resolvedDisplayMode() !== 'hidden'),expression:"child.resolvedDisplayMode() !== 'hidden'"}],staticClass:"white--text",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $expand")])]},proxy:true}],null,true)},[_c('div',[_c(VIcon,{staticClass:"pr-3 white--text"},[_vm._v(_vm._s(("mdi-numeric-" + (i + 1) + "-box"))+" ")]),_c('i18n',{attrs:{"path":child.name,"tag":"span"}})],1)]),_c(VExpansionPanelContent,{directives:[{name:"show",rawName:"v-show",value:(child.resolvedDisplayMode() !== 'hidden'),expression:"child.resolvedDisplayMode() !== 'hidden'"}]},[_c('div',{staticClass:"mt-5"}),_vm._l((child.children),function(accordianChild){return _c('div',{key:accordianChild.id},[_c(_vm.ComponentTypeDictionaryService.getComponentFromDictionary(
              accordianChild.type
            ),{tag:"component",staticClass:"mt-3",attrs:{"entry":accordianChild}})],1)})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }