import {
  FormValidator,
  FormValidatorBase,
} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import RequiredFormValidator from "@/app/dynamic-components/forms/form-validations/validators/required.form-validator";
import MinSizeFormValidator from "@/app/dynamic-components/forms/form-validations/validators/min-size.form-validator";
import MinLengthFormValidator from "@/app/dynamic-components/forms/form-validations/validators/min-length.form-validator";
import MaxLengthFormValidator from "@/app/dynamic-components/forms/form-validations/validators/max-length.form-validator";

export class FormValidatorLookup {
  lookup: { [key: string]: FormValidator } = {};

  constructor() {
    this.registerValidator(new RequiredFormValidator());
    this.registerValidator(new MinSizeFormValidator());
    this.registerValidator(new MinLengthFormValidator());
    this.registerValidator(new MaxLengthFormValidator());
  }

  public registerValidator(validator: FormValidator) {
    this.lookup[validator.getKey()] = validator;
  }

  public getValidator(key: string): FormValidator | null {
    const r = this.lookup[key];
    if (r) return r;
    return null;
  }

  parseValidators(validators: string[]): FormValidator[] {
    if (validators.length <= 0) return [];
    const collector: FormValidator[] = [];

    validators.forEach((v) => {
      let indexOfParams = v.indexOf("(");
      let params: any[];
      if (indexOfParams < 0) {
        indexOfParams = v.length;
        params = [];
      } else {
        /*console.warn(
          "getting params",
          indexOfParams + 1,
          v.length - 1,
          v.substring(indexOfParams + 1, v.length - 1).split(",")
        );*/
        params = v.substring(indexOfParams + 1, v.length - 1).split(",");
      }

      const key = v.substring(0, indexOfParams);
      const validator = this.lookup[key];
      if (validator) {
        const validatorImpl = validator.constructImpl(params);
        collector.push(validatorImpl);
      }
    });

    return collector;
  }

  hasRequiredValidator(validators: string[]): boolean {
    if (validators.length <= 0) return false;

    for (const v of validators) {
      let indexOfParams = v.indexOf("(");
      let params: any[];
      if (indexOfParams < 0) {
        indexOfParams = v.length;
      }

      const key = v.substring(0, indexOfParams);
      if ("required" === key) return true;
    }

    return false;
  }
}

export const formValidatorLookup: FormValidatorLookup =
  new FormValidatorLookup();
