import EventBase from "@/app/events/event-base";
import router, { Routes } from "@/router";
import {ContextAware, DetailContext} from "@/app/contexts/detail.context";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

export default class ModifyLotsEvent extends EventBase {
  async getCallBack(context: ContextAware, payload: any, rawPayload: any): Promise<any> {
    if(!(context instanceof DetailContext)) return Promise.reject('Context is not a detailcontext');
    const detailsContext: DetailContext = context;

    if (detailsContext.root && detailsContext.root instanceof DossierDetailComponent) {
      return;
    }
  }
}
