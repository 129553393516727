






































import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import Component from "vue-class-component";
import router from "@/router";
import { RenderType } from "@/app/Types";
import { formService } from "@/app/services/form.service";
import { Vue } from "vue-property-decorator";
import { DossierCreationModel } from "@/app/models/dossier/creation/dossier-creation.model";
import { DossierEditModel } from "@/app/models/dossier/edit/dossier-edit-model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import DynamicComponentsEntryList from "@/app/components/dynamic-components-entry-list.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import analyticsService from "@/services/analytics.service";
import {ExternalContext} from "@/app/contexts/externalContext";

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: {
    DynamicComponentsEntryList,
    AuthenticationInterceptor,
    PageHeader,
    AcceptPoliciesInterceptor,
    FormWrapper,
  },
})
export default class DossierEditView extends ActivityDependendViewBase {
  protected dossierDefinitionId = "";
  protected externalContext = new ExternalContext();
  protected dossierId = "";
  protected isSaving = false;
  protected formStartTime: number | null = null;
  protected dossierDefinitionEditModel: DossierEditModel = {
    form: {
      id: "",
      children: [],
      name: "",
      type: RenderType.LinearSection,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
    },
  };
  protected form: DynamicForm | null = null;
  formData: any = null;
  initialFormData: any = null;
  dossierData: any = null;

  async onPageReady(): Promise<any> {
    this.getDossierDefinition();
    this.getDossierDetailData();

    this.dossierDefinitionId = this.activityDefinition?.dossiers?.entries[0]?.dossierModifyDefinitionId;
    this.externalContext.setData("user", this.$store.state.user);
    this.externalContext.setData("activity", this.activityDefinition);
  }

  getDossierDetailData(): void {
    this.dossierId = this.$route.params.id;

    if (this.dossierId) {
      dossierServiceV2
        .getDossierData(this.dossierId)
        .then((value) => {
          this.initialFormData = value.data;
          this.dossierData = value;
          this.form?.patchForm(this.initialFormData);
        })
        .catch((reason) => {
          console.error("Could not find data for this id");
        });
    }
  }

  getDossierDefinition(): void {
    this.dossierDefinitionId = this.activityDefinition?.dossiers?.entries[0]?.dossierModifyDefinitionId;

    if (this.dossierDefinitionId) {
      dossierServiceV2
        .getDossierEditDefinition(this.dossierDefinitionId)
        .then((value) => {
          this.dossierDefinitionEditModel = value;
        })
        .catch((reason) => {
          console.error("Could not find edit definition model");
        });
    }
  }

  mounted(): void {
    this.trackFormStart();
  }


  goBack() {
    router.back();
  }

  formDataChanged(formData: any) {
    this.formData = formData;
  }

  formInitialized(form: DynamicForm) {
    this.form = form;
    if (this.initialFormData) {
      this.form.patchForm(this.initialFormData);
    }
  }

  async handleEditDossier() {
    if(!this.form) return;
    const success = await FormWrapper.SAVE_FORM_ASYNC(this.form, () => this.isSaving = true, () => this.isSaving = false, async (formData, form) => {
      const result = await dossierServiceV2.updateDossier(formData, this.dossierDefinitionId, this.dossierId, form, this.dossierData.status, true);
      return {
        type: "dossier",
        id: this.dossierId
      }
    });
    if(success){
      Vue.$toast.success("Successfully saved.");
      this.trackFormSubmission();
      this.goBack();
    }
  }

  trackFormStart() {
    this.formStartTime = Date.now();
    //console.log('trackFormStart');
    analyticsService.sendEvent('Form Interaction', 'dossiers_edit_form_open', 'dossiers-edit-view Form Opened', 1);
  }

  // Function to track form submission time
  trackFormSubmission() {
    const formEndTime = Date.now();
    if (this.formStartTime) {
      const timeSpent = (formEndTime - this.formStartTime) / 1000; // Convert time spent to seconds
      //console.log('trackFormSubmission timespent: ' + timeSpent);
      analyticsService.sendEvent('Form Interaction', 'dossiers_edit_form_submit', 'dossiers-edit-view Form Submitted ', 1, {
        time_spent: timeSpent,
      });
    }
  }
}
