import NotificationDto from "@/contracts/notification/NotificationDto";
import axios from "@/plugins/axios";
import store from "@/store";
import { Subject, Subscription } from "rxjs";

class NotificationService {

  private staticNotificationSubject = new Subject<{ title: string; message: string; type: string } | null>()
  public getRelevantNotification(): Promise<NotificationDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/notification`)
      .then((response) => {
        return response.data;
      });
  }

    setStaticNotification(param: { title: string; message: string; type: string } | null) {
        this.staticNotificationSubject.next(param);
    }

    getStaticNotifications(){
      return this.staticNotificationSubject.asObservable();
    }
}

export const notificationService = new NotificationService();
