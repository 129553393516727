import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { OverviewContext } from "@/app/contexts/overview.context";
import { ruleEngine } from "@/app/services/rule.engine";

@Component({})
export default class OverviewCellBase extends Vue {
  protected value: any = null;

  protected resolveDebounceSubject: Subject<any> | null = null;
  protected resolveDebounceSubscription: Subscription | null = null;

  @Prop({ default: null })
  protected item!: any;


  protected overviewContext: OverviewContext = new OverviewContext();
  @Prop({ default: () => { return null;} })
  protected overviewBaseContext!: OverviewContext;

  @Watch("overviewBaseContext", { immediate: true })
  onOverviewContext(
    newOverviewContext: OverviewContext | null,
    oldOverviewContext: OverviewContext | null
  ) {
    if (newOverviewContext) {
      this.overviewContext.inherit(newOverviewContext);
      this.overviewContext.setData('item',this.item);
    }
  }

  @Prop({ default: null })
  protected headerId!: string;

  @Prop({ default: null })
  protected renderType!: string;

  @Prop({ default: null })
  protected dataSelector!: string | null;

  @Prop({ default: {} })
  protected metadata!: { [key: string]: any };

  @Prop({ default: null })
  protected additionalMetadata!: { [key: string]: any } | null;

  @Prop({ default: null })
  protected eventbus!: Subject<OverviewEvent> | null;

  public rules: {[key: string]: boolean} = {};
  @Prop({ default: () => {return {};} })
  protected itemRules!: {[key: string]: string};


  @Watch("dataSelector", { immediate: true, deep: true })
  setDataSelector(
    newDataSelector: string | null,
    oldDataSelector: string | null
  ) {
    if (this.item && this.dataSelector && this.headerId) {
      this.resolveValue();
    }
  }

  @Watch("item", { immediate: true, deep: true })
  setItem(newItem: any, oldItem: any) {
    if (this.item && this.headerId) {
      this.overviewContext.setData('item',this.item);
      const resolvedRules: { [key: string]: string } = this.overviewContext.resolvePlaceholders(this.itemRules || {});
      this.rules = {};
      Object.entries(resolvedRules).map(e => {
        this.rules[e[0]] = ruleEngine.resolveRule(this.overviewContext, e[1] as string || '');
      })

      this.resolveValue();
    }
  }

  private resolveValue() {
    if (!this.resolveDebounceSubscription) {
      this.resolveDebounceSubject = new Subject<any>();
      this.resolveDebounceSubscription = this.resolveDebounceSubject
        .pipe(debounceTime(200))
        .subscribe((val) => {
          if (this.dataSelector) {
            if (!this.dataSelector.startsWith("data.item.")) {
              this.value = this.resolveDataPath(
                "data.item." + this.dataSelector
              );
            } else {
              this.value = this.resolveDataPath(this.dataSelector);
            }
          } else {
            this.value = this.item[this.headerId];
            if (this.value == undefined) this.value = null;
          }
          this.PostResolveValue();
          this.$forceUpdate();
        });
    }
    this.resolveDebounceSubject?.next(true);
  }

  protected PostResolveValue() {
    return;
  }

  protected resolvePlaceholdersString(str: any): any {
    if(str === undefined || str === null) return str;
    const calculatedReplaceRegex = /\${([^$}]*)}/;

    let replaceRegex;
    let c = 0;
    while ((replaceRegex = calculatedReplaceRegex.exec(str)) && c < 1000) {
      let value = this.resolveDataPath(replaceRegex[1]);
      //console.log('resolveDataPath', str , value);
      if (Array.isArray(value)) {
        value = Array.from(value).length <= 0 ? null : value[0];
      }
      str = str.replaceAll(replaceRegex[0], value ? value : "$nan");
      //console.log('found regex', JSON.stringify(replaceRegex), value,str);
      c++;
    }
    return str;
  }

  public resolveDataPath(dataPath: string): any {
    if (dataPath.startsWith("data.item.")) {
      dataPath = dataPath.replace("data.item.", "");
      return getObjectContentsFromPath(dataPath, this.item);
    }
    if (dataPath.startsWith("data.metadata.")) {
      dataPath = dataPath.replace("data.metadata.", "");
      return getObjectContentsFromPath(dataPath, this.metadata);
    }
  }
  beforeDestroy(): void {
    if (this.resolveDebounceSubscription)
      this.resolveDebounceSubscription.unsubscribe();
    this.resolveDebounceSubscription = null;
  }
}
